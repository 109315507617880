/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ValueLimit = 'Upper' | 'Lower' | 'Equals' | 'NotEquals' | 'None';

export const ValueLimit = {
    UPPER: 'Upper' as ValueLimit,
    LOWER: 'Lower' as ValueLimit,
    EQUALS: 'Equals' as ValueLimit,
    NOT_EQUALS: 'NotEquals' as ValueLimit,
    NONE: 'None' as ValueLimit
};


/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CombinedAlertRuleType = 'Unspecified' | 'Container' | 'ValueCompare' | 'GeoFence' | 'TimeTriggeredDeviceCount' | 'TimeTriggeredMessageCount' | 'TimeTriggeredTimeInThreshold';

export const CombinedAlertRuleType = {
    UNSPECIFIED: 'Unspecified' as CombinedAlertRuleType,
    CONTAINER: 'Container' as CombinedAlertRuleType,
    VALUE_COMPARE: 'ValueCompare' as CombinedAlertRuleType,
    GEO_FENCE: 'GeoFence' as CombinedAlertRuleType,
    TIME_TRIGGERED_DEVICE_COUNT: 'TimeTriggeredDeviceCount' as CombinedAlertRuleType,
    TIME_TRIGGERED_MESSAGE_COUNT: 'TimeTriggeredMessageCount' as CombinedAlertRuleType,
    TIME_TRIGGERED_TIME_IN_THRESHOLD: 'TimeTriggeredTimeInThreshold' as CombinedAlertRuleType
};


import { Injectable } from '@angular/core';
import {
  AlertModel,
  AlertsDownloadRequest,
  AlertStatistics,
  FileDownloadResponse,
  FileType,
  UpdateAlertStatusRequest,
  UpdateAlertStatusToDeactivatedRequest,
  AlertService,
  AlertRemarkModel,
  AlertsRequest,
  AlertsResponse,
} from '@dpdhl-iot/api/backend';
import { EMPTY, expand, from, Observable, reduce } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl-iot/api/management';
import { CoreConstants } from '../../constants/core-constants';

@Injectable({
  providedIn: 'root',
})
export class AlertManagementService {
  private selectedApplication: IotApplicationModel;

  constructor(
    private readonly selectedApplicationService: ApplicationDataService,
    private readonly alertService: AlertService,
  ) {
    this.selectedApplicationService.application$
      .pipe(
        filter((a) => !!a.application),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((app) => {
        this.selectedApplication = app;
      });
  }

  public getAlertsRecursive(alertRequest: AlertsRequest): Observable<AlertModel[]> {
    return this.getAlerts(alertRequest).pipe(
      expand((result) => {
        if (result.next) {
          alertRequest.pageSize = 5000;
          alertRequest.cursor = result.next;
          return this.getAlerts(alertRequest);
        } else {
          return EMPTY;
        }
      }),
      map((a) => a.alerts!),
      reduce((acc: AlertModel[], x: AlertModel[]) => acc.concat(x ?? []), []),
    );
  }

  public getAlertRemarks(alertId: string): Observable<Array<AlertRemarkModel>> {
    return this.alertService.getAlertRemarks(
      alertId,
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
    );
  }

  public downloadAlerts(
    downloadFileType: FileType,
    alertsDownloadRequest: AlertsDownloadRequest,
  ): Observable<FileDownloadResponse> {
    return this.alertService.alertsDownload(
      this.selectedApplication.id,
      downloadFileType,
      CoreConstants.API_VERSION,
      alertsDownloadRequest,
    );
  }

  public deactivateAlertStatus(
    updateAlertStatusRequest: UpdateAlertStatusToDeactivatedRequest,
  ): Observable<AlertModel[]> {
    return this.alertService.updateAlertStatusToDeactivated(
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
      updateAlertStatusRequest,
    );
  }

  public updateAlertStatus(
    alertId: string,
    facilityId: string,
    updateRequest: UpdateAlertStatusRequest,
  ): Observable<AlertModel> {
    return this.alertService.updateAlertStatus(
      alertId,
      facilityId,
      CoreConstants.API_VERSION,
      updateRequest,
    );
  }

  public getAlertStatistics(): Observable<AlertStatistics> {
    return from(
      this.alertService.getAlertStatistics(this.selectedApplication.id, CoreConstants.API_VERSION),
    );
  }

  private getAlerts(alertRequest: AlertsRequest): Observable<AlertsResponse> {
    return this.alertService.getAlerts(
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
      alertRequest,
    );
  }
}

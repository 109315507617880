import { TemplateRef } from '@angular/core';
import { PageSizeOption } from './pagination/pagination.types';

export namespace DPDHLTable {
  export enum CellType {
    TEXT = 'text',
    CUSTOM = 'custom',
    EXPANDER = 'expander',
  }

  export interface DownloadConfig {
    fileName?: string;
    sheetName?: string;
    header?: string[];
    toRowEntry?: (row: any) => { [key: string]: string | number | Date };
    dateFormat?: string;
  }

  export interface SortingConfig {
    defaultSortField: string;
    defaultSortDirection?: 'ascending' | 'descending';
  }

  export interface ColumnSortingConfig {
    sort: (a: any, b: any) => number;
  }

  export interface PaginationConfig {
    pageSize?: number;
    pageSizeOptions?: PageSizeOption[];
    enablePageSizeSelection: boolean;
    storageType?: PaginationStorageType;
  }

  export interface TableRow {
    [key: string]: any;
    expanded?: boolean;
  }

  export interface DetailRowConfig {
    field: string;
    template: TemplateRef<any> | null;
  }

  export interface ActionRowConfig {
    showSearch: boolean;
    showExport: boolean;
  }

  export enum PaginationStorageType {
    SESSION_STORAGE = 'SESSION_STORAGE',
    LOCAL_STORAGE = 'LOCAL_STORAGE',
  }
}

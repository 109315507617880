import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

export enum TablePaginationVariant {
  DEFAULT = 'default',
  CURSOR = 'cursor',
}

export enum TableDownloadEventType {
  START = 'start',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface TableDownloadEvent {
  type: TableDownloadEventType;
  error?: any;
}

export type TableDownloadData = TableDownloadRow[];

export interface TableDownloadRow {
  [key: string]: string | number | Date;
}

export type TableRowSelectEvent = TableSingleRowSelectEvent;

export interface TableSingleRowSelectEvent {
  [key: string]: TableRow;
}

export interface TableSelectionConfig {
  idField: string;
}

export interface TableGroupConfig {
  field: string;
}

export interface DefaultPagination {
  pageSize: number;
  totalElementCount: number;
}

export interface CursorPagination {
  remainingElementCount: number;
  totalElementCount: number;
}

export type TablePagination = DefaultPagination | CursorPagination;

export enum TableSortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface TableColumnSortConfig {
  sort: (a: any, b: any) => number;
}

export interface TableColumnSortEvent {
  column: TableColumn;
  direction: TableSortDirection;
}

export interface TableColumnFilterEvent {
  column: TableColumn;
  selectedItems: TableColumnFilterItem[];
}

export interface TableFilterEvent {
  column: {
    field: string;
  };
  selectedItemValues: any[];
}

export interface TableSortEvent {
  column: {
    field: string;
  };
  direction: TableSortDirection;
}

export interface TableData {
  elements: TableRow[];
  loading: boolean;
  pagination?: TablePagination;
}

export interface TableSortingConfig {
  defaultSortField: string;
  defaultSortDirection?: TableSortDirection;
}

export interface TableColumnFilterItem {
  displayName: string;
  value: any;
  checked?: boolean;
}

export type TableColumnFilterConfig = SyncTableColumnFilterConfig | AsyncTableColumnFilterConfig;

export interface SyncTableColumnFilterConfig {
  items: TableColumnFilterItem[];
}

export interface AsyncTableColumnFilterConfig {
  items$: Observable<TableColumnFilterItem[]>;
}

export interface TableColumn {
  field: string;
  header: string;
  width: string;
  customTemplate?: TemplateRef<any>;
  sortable: boolean;
  sortConfig?: TableColumnSortConfig;
  filterable?: TableColumnFilterConfig;
  showLoadingSkeleton: boolean;
  headerTemplate?: TemplateRef<any>;
}

export interface TableActionRowConfig {
  showSearch: boolean;
  showExport: boolean;
  searchPlaceholder?: string;
}

export interface TableRows {
  elements: TableRow[];
  stayOnPage?: boolean;
}

export interface TableRow {
  [key: string]: any;
}

export interface TableDownloadConfig {
  fileName?: string;
  sheetName?: string;
  header?: string[];
  toRowEntry?: (row: any) => TableDownloadRow;
  dateFormat?: string;
  downloadFormat?: DownloadReportType;
}

export enum DownloadReportType {
  PDF = 'Pdf',
  EXCEL = 'Excel',
}

export enum TableQueryStorageType {
  QUERY_PARAMS = 'QUERY_PARAMS',
  SESSION_STORAGE = 'SESSION_STORAGE',
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule, SelectModule, SvgIconModule } from '@dpdhl/angular-shared-ui';
import { CursorPaginationComponent } from './cursor-pagination/cursor-pagination.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PaginationComponent, CursorPaginationComponent],
  imports: [
    CommonModule,
    ButtonModule,
    SvgIconModule,
    TranslocoModule,
    SelectModule,
    TranslateModule,
  ],
  exports: [PaginationComponent],
})
export class PaginationModule {}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DEFAULT_PREFERENCE, UserPreferenceMapperService } from './user-preference-mapper.service';
import { UserPreference } from './users.types';
import { AppInsightsService, UserSettingsDataService } from '@dpdhl/iot-shared-ui';
import { UserSettingValue } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/settings-api';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceDataService {
  constructor(
    private readonly usersMapper: UserPreferenceMapperService,
    private readonly appInsightsService: AppInsightsService,
    private readonly userSettingsDataService: UserSettingsDataService,
  ) {}

  get userPreference$(): Observable<UserPreference> {
    return this.userSettingsDataService.getGlobalUserSettingValues().pipe(
      map((userSettingValues: UserSettingValue[]) =>
        this.usersMapper.toUserPreference(userSettingValues),
      ),
      catchError((error) => {
        this.appInsightsService.trackError(error);
        return of(DEFAULT_PREFERENCE);
      }),
    );
  }
}

<dpdhl-breadcrumbs data-test-id="alertsBreadcrumbs"></dpdhl-breadcrumbs>

<div class="p-24 bg-white" *ngIf="viewConfiguration">
  <div class="flex items-center space-x-8 mr-8">
    <div class="flex space-x-8 flex-grow">
      <svg-icon
        svgClass="ml-8 mt-4 w-16 h-16"
        src="assets/images/CoMo/alert-default-icon.svg"
        data-test-id="alertsSvgHeaderIcon"
      >
      </svg-icon>
      <h2 class="font-black-condensed text-headline1 pl-8">
        {{ 'dashboard.alert.overview' | translate | uppercase }}
      </h2>
      <img
        src="assets/images/CoMo/info_outline.svg"
        alt="alert"
        title="{{ helpText | translate }}"
        class="helper-text"
        data-test-id="infobox"
      />
    </div>
    <div class="flex items-center mr-8 space-x-8" *ngIf="showDownLoadAlerts">
      <div class="w-[20rem]">
        <dpdhl-date-range-picker
          [maxDateRange]="180"
          [_formGroup]="downloadForm"
          [minDate]="minDate"
          [maxDate]="maxDate"
          _formControlName="dateRange"
          label="{{ 'common.selectDateRange' | translate }}"
          title="{{ 'dashboard.alert.dateRangeHint' | translate }}"
          data-test-id="dateRange"
        >
        </dpdhl-date-range-picker>
      </div>
      <div class="w-[10rem]">
        <dpdhl-select
          [_formGroup]="downloadForm"
          [_formControlName]="'downloadType'"
          label=""
          title="{{ 'dashboard.alert.fileFormatHint' | translate }}"
          data-test-id="selectFileFormat"
        >
          <ng-container *ngFor="let item of downloadTypes">
            <dpdhl-select-option [value]="item" [display]="item"></dpdhl-select-option>
          </ng-container>
        </dpdhl-select>
      </div>
      <div>
        <dpdhl-button
          variant="secondary"
          form="button-icon"
          type="button"
          (buttonClick)="downloadAlertList()"
          data-test-id="alertsDownload"
          title="{{ 'dashboard.alert.downloadHint' | translate }}"
        >
          <svg-icon src="assets/icons/download-file.svg" svgClass="w-24 h-24"></svg-icon>
        </dpdhl-button>
      </div>
    </div>
    <div *ngIf="isGridLoading" class="spinner">
      <div class="spinner-icon"></div>
    </div>
  </div>
  <ng-container>
    <div class="pb-16">
      <dpdhl-tabs
        [active]="selectedTab"
        data-test-id="alertsTabControl"
        (select)="alertTabSelected($event)"
      >
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabUnresolved"
          title="{{ 'dashboard.alert.tab.unresolvedHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.unresolved' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ unResolvedAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabResolved"
          title="{{ 'dashboard.alert.tab.resolvedHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.resolved' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ resolvedAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
        <dpdhl-tab-element
          *dpdhlTabElement
          data-test-id="tabAll"
          title="{{ 'dashboard.alert.tab.allHint' | translate }}"
        >
          {{ 'dashboard.alert.tab.all' | translate }}
          <span class="text-caption2 text-secondary"
            >({{ totalAlertsCount | number: '' : 'de-DE' }})</span
          >
        </dpdhl-tab-element>
      </dpdhl-tabs>
    </div>
  </ng-container>
  <app-alert-list-table
    [alertData]="alertData"
    [isLoading]="isPageLoading"
    [selectableFacilities]="selectableFacilities"
    [viewConfiguration]="viewConfiguration"
    [workFlowFacilities]="workFlowFacilities"
    [hasAlertUpdatePermissionFacilities]="hasAlertUpdatePermissionFacilities"
    [alertAction]="alertAction"
    (selectAlertAction)="onSelectAlertActionRow($event)"
    (updatedAlertWorkflow)="onUpdateAlertStatus($event)"
    (closeAlertWorkflow)="onCloseAlertStatus()"
  ></app-alert-list-table>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { TableColumnComponent } from './table-column/table-column.component';
import { TableRowComponent } from './table-row/table-row.component';
import { PaginationModule } from '../pagination/pagination.module';
import { TableActionRowComponent } from './table-action-row/table-action-row.component';
import { TranslocoModule } from '@jsverse/transloco';
import {
  ButtonModule,
  CardModule,
  ChipModule,
  InputModule,
  LoadingModule,
  ModalModule,
  SearchModule,
  SelectModule,
  SvgIconModule,
} from '@dpdhl/angular-shared-ui';

@NgModule({
  imports: [
    CommonModule,
    ChipModule,
    SvgIconModule,
    CardModule,
    LoadingModule,
    PaginationModule,
    ButtonModule,
    InputModule,
    SearchModule,
    ModalModule,
    SelectModule,
    TranslocoModule,
  ],
  declarations: [TableComponent, TableColumnComponent, TableRowComponent, TableActionRowComponent],
  exports: [TableComponent, TableColumnComponent],
})
export class TableModule {}

import { Injectable } from '@angular/core';
import { PageChangeEvent } from './pagination.types';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  getPageChange(page: number, pageSize: number): PageChangeEvent {
    const from: number = page * pageSize;
    const to: number = (page + 1) * pageSize;

    return { from, to, page };
  }
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { TableColumnComponent } from '../table-column/table-column.component';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { TableComponentDataService } from '../table-component-data.service';
import { PaginationComponentDataService } from '../../pagination/pagination-component-data.service';
import { PaginationService } from '../../pagination/pagination.service';
import { DPDHLTable } from '../../como-ui.types';
import { PageChangeEvent, PageSizeOption } from '../../pagination/pagination.types';
import PaginationConfig = DPDHLTable.PaginationConfig;

@Component({
  selector: 'dpdhl-table',
  templateUrl: './table.component.html',
  providers: [TableComponentDataService, PaginationComponentDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() data: { elements: DPDHLTable.TableRow[]; stayOnPage?: boolean } = {
    elements: [],
    stayOnPage: false,
  };
  @Input() loading = true;
  @Input() pageSize!: number;
  @Input() withActionRow: boolean | DPDHLTable.ActionRowConfig = false;
  @Input() downloadConfig: DPDHLTable.DownloadConfig = {};
  @Input() sorting: DPDHLTable.SortingConfig | undefined;
  @Input() prefix = 'TABLE';
  @Input() paginationConfig: PaginationConfig = {
    pageSize: 10,
    pageSizeOptions: [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
    enablePageSizeSelection: true,
  };

  /**
   * @deprecated this is only temporarily available to ease the migration from syncfusion components
   */
  @Input() detailRowConfig: DPDHLTable.DetailRowConfig = { field: '', template: null };

  @ContentChildren(TableColumnComponent) columns!: QueryList<TableColumnComponent>;

  actionRowConfig: DPDHLTable.ActionRowConfig = { showExport: false, showSearch: false };
  gridColsStyle!: { [key: string]: string };
  dataChunk$: ReplaySubject<{ elements: DPDHLTable.TableRow[]; stayOnPage?: boolean }> =
    new ReplaySubject();
  filteredData: { elements: DPDHLTable.TableRow[]; stayOnPage?: boolean } = {
    elements: [],
    stayOnPage: false,
  };

  currentSort: { column: TableColumnComponent; direction: 'ascending' | 'descending' } | undefined;

  initialPage?: number;

  private afterViewInit$: Subject<void> = new Subject();
  private dataChange$: ReplaySubject<{ elements: DPDHLTable.TableRow[]; stayOnPage?: boolean }> =
    new ReplaySubject();
  private subs: Subscription[] = [];

  constructor(
    private readonly dataService: TableComponentDataService,
    private readonly paginationDataService: PaginationComponentDataService,
    private readonly paginationService: PaginationService,
    private readonly ref: ChangeDetectorRef
  ) {}

  get skeletonColumns() {
    const all = this.columns.map((c) => ({ show: c.showLoadingSkeleton }));
    return this.detailRowConfig.template ? [{ show: false }, ...all] : all;
  }

  ngOnInit() {
    this.actionRowConfig =
      typeof this.withActionRow === 'boolean'
        ? { showExport: this.withActionRow, showSearch: this.withActionRow }
        : this.withActionRow;

    this.dataService.setPrefix(this.prefix);
    this.initialPage = this.dataService.initialPage;

    this.pageSize = this.paginationConfig?.pageSize || this.pageSize || 10;

    if (this.paginationConfig?.enablePageSizeSelection && this.paginationConfig?.storageType) {
      const storedPageSize = this.dataService.getPageSize(this.paginationConfig.storageType);
      this.pageSize = storedPageSize || this.pageSize;
    }

    this.subs.push(
      combineLatest([this.dataChange$, this.afterViewInit$]).subscribe({
        next: ([data]) => {
          const searchFilter: string | undefined = this.dataService.initialSearchValue;

          if (searchFilter && this.actionRowConfig.showSearch) {
            this.onSearchListByValue(searchFilter);
          } else {
            this.updateData(data);
          }

          this.ref.markForCheck();
        },
      })
    );
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.pageSize ??= 20;

    if (!simpleChanges.data || !simpleChanges.data.currentValue) {
      return;
    }

    this.dataChange$.next(simpleChanges.data.currentValue);
  }

  ngAfterContentInit() {
    let columns = this.columns.map((column) => {
      return column.width || '1fr';
    });
    if (this.detailRowConfig.template) {
      columns = ['0.15fr', ...columns];
    }
    this.gridColsStyle = {
      'grid-template-columns': `${columns.join(' ')}`,
    };

    if (this.sorting) {
      this.currentSort = this.getDefaultSorting();
    }

    this.afterViewInit$.next();
  }

  get dataAvailable() {
    return !this.loading && !!this.filteredData && !!this.filteredData.elements.length;
  }

  get dataEmpty() {
    return !this.loading && !this.filteredData?.elements.length;
  }

  get withPagination() {
    return this.filteredData && this.filteredData.elements.length > this.pageSize;
  }

  get pageSizeOptions(): PageSizeOption[] {
    if (this.paginationConfig?.enablePageSizeSelection) {
      return this.paginationConfig.pageSizeOptions || [];
    }

    return [];
  }

  getSortIconSrc(column: TableColumnComponent): string {
    if (!this.currentSort) {
      return '/assets/icons/sort--asc.svg';
    }

    if (this.currentSort && this.currentSort.column === column) {
      if (this.currentSort.direction === 'ascending') {
        return '/assets/icons/sort--asc.svg';
      } else {
        return '/assets/icons/sort--desc.svg';
      }
    }

    return '/assets/icons/sort.svg';
  }

  onPageChange({ from, to, page }: PageChangeEvent) {
    this.dataService.updateParams({ page: page + '' });
    this.dataChunk$.next({
      elements: this.filteredData.elements.slice(from, to),
      stayOnPage: this.filteredData.stayOnPage,
    });
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    if (this.paginationConfig?.enablePageSizeSelection && this.paginationConfig?.storageType) {
      this.dataService.setPageSize(pageSize, this.paginationConfig.storageType);
    }
  }

  onSearchListByValue(searchValue: string | null) {
    this.dataService.updateParams({ searchValue: searchValue || undefined });

    if (!searchValue) {
      this.updateData(this.data);
      return;
    }

    const filteredData: { elements: DPDHLTable.TableRow[]; stayOnPage?: boolean } =
      this.filterByValue(searchValue);
    this.updateData(filteredData);
  }

  onSort(column: TableColumnComponent) {
    this.currentSort = this.getNewSort(column);
    this.updateData(this.filteredData);
  }

  private getNewSort(
    column: TableColumnComponent
  ): { column: TableColumnComponent; direction: 'ascending' | 'descending' } | undefined {
    // if previous sorting is undefined we start with ascending
    if (!this.currentSort || this.currentSort.column !== column) {
      return { column, direction: 'ascending' };
    }

    // if current column is default field we need to invert the current sorting
    if (this.currentSort.column.field === this.sorting?.defaultSortField) {
      if (this.currentSort.direction === 'ascending') {
        return { column, direction: 'descending' };
      }

      return { column, direction: 'ascending' };
    }

    // otherwise we will iterate through asc -> desc -> default
    if (this.currentSort.direction === 'ascending') {
      return { column, direction: 'descending' };
    }

    return this.getDefaultSorting();
  }

  private getDefaultSorting(): {
    column: TableColumnComponent;
    direction: 'ascending' | 'descending';
  } {
    const defaultColumn: any = this.columns.find(
      (column) => column.field === this.sorting?.defaultSortField
    );
    return {
      column: defaultColumn as TableColumnComponent,
      direction: this.sorting?.defaultSortDirection || 'descending',
    };
  }

  private updateData(data: { elements: DPDHLTable.TableRow[]; stayOnPage?: boolean }) {
    const sortedData = this.sortData(data);

    this.filteredData = sortedData;

    if (sortedData.elements.length <= this.pageSize) {
      this.dataChunk$.next(sortedData);
    }

    if (sortedData.elements.length > this.pageSize) {
      if (!data.stayOnPage) {
        this.paginationDataService.onPage(0);

        const pageChange: PageChangeEvent = this.paginationService.getPageChange(0, this.pageSize);
        this.onPageChange(pageChange);
      } else {
        const pageChange: PageChangeEvent = this.paginationService.getPageChange(
          this.dataService.initialPage,
          this.pageSize
        );
        this.onPageChange(pageChange);
      }
    }
  }

  private sortData(data: { elements: DPDHLTable.TableRow[]; stayOnPage?: boolean }): {
    elements: DPDHLTable.TableRow[];
    stayOnPage?: boolean;
  } {
    if (!this.sorting) {
      return data;
    }

    const sortConfig: { column: TableColumnComponent; direction: 'ascending' | 'descending' } = this
      .currentSort as {
      column: TableColumnComponent;
      direction: 'ascending' | 'descending';
    };

    const sortedElems = [...data.elements].sort((a, b) => {
      const aField: string | undefined = a[sortConfig.column?.field];
      const bField: string | undefined = b[sortConfig.column?.field];

      if (!aField) {
        return -1;
      }

      if (!bField) {
        return 1;
      }

      if (sortConfig.direction === 'ascending') {
        if (sortConfig.column.sortConfig) {
          return sortConfig.column.sortConfig.sort(aField, bField);
        }

        return aField.toString().localeCompare(bField.toString());
      }

      if (sortConfig.column.sortConfig) {
        return -1 * sortConfig.column.sortConfig.sort(aField, bField);
      }

      return bField.toString().localeCompare(aField.toString());
    });

    return {
      elements: sortedElems,
      stayOnPage: data.stayOnPage,
    };
  }

  private filterByValue(searchValue: string): {
    elements: DPDHLTable.TableRow[];
    stayOnPage?: boolean;
  } {
    const filteredElems = this.data.elements.filter((o) =>
      Object.entries(o)
        .filter(([key, _]: [string, any]) => {
          // if we have columns, then we only want to filter for properties that are shown inside the table
          return (
            !this.columns ||
            !this.columns.length ||
            this.columns.find((column) => column.field === key)
          );
        })
        .some(([_, value]: [string, any]) => {
          return JSON.stringify(value).toLowerCase().includes(searchValue.toLowerCase());
        })
    );

    return {
      elements: filteredElems,
      stayOnPage: this.data.stayOnPage,
    };
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}

import { AlertStatus } from '@dpdhl-iot/api/backend';

export class CoreConstants {
  public static readonly MSALTOKEN_REFRESH_INTERVAL: number = 4 * 60 * 1000;
  public static readonly TEN_MINUTES_INTERVAL: number = 10 * 60 * 1000;
  public static readonly FIFTEEN_MINUTES_INTERVAL_SECONDS: number = 15 * 60;
  public static readonly FIFTEEN_MINUTES_INTERVAL_MILLISECONDS: number =
    CoreConstants.FIFTEEN_MINUTES_INTERVAL_SECONDS * 1000;
  public static readonly ONE_MONTH_INTERVAL_SECONDS: number = 31 * 24 * 60 * 60;
  public static readonly ONE_HOUR_INTERVAL: number = 60 * 60 * 1000;
  public static readonly ONE_DAY_INTERVAL: number = 24 * 60 * 60 * 1000;

  public static readonly ERRORCODE = 'ErrorCode';

  public static readonly Email_Regex =
    /^([a-zA-Z0-9_\-.]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|\d{1,3})(]?)$/;
  public static readonly Mobile_Regex = /^([+])\d{6,14}$/;

  public static readonly API_VERSION = '1';

  public static readonly EXTERNAL_WEATHER_LEGEND = 'room.chart.externalWeather';

  public static readonly PREDMAIN_SENSOR_WRITE_PERMISSION = 'XBUCM.IPM.W';

  // This hardcoded APPLICATION_GROUP_ID
  // used to identify the application group is CoMo
  // will be removed after the Refactoring
  public static readonly COMO_APPLICATION_GROUP_ID = '750d70d8-6846-4a88-96b8-8ac18c239c0c';

  public static readonly CHART_ALERT_STATUSES = [
    AlertStatus.OPEN,
    AlertStatus.DEACTIVATED,
    AlertStatus.MANUALLY_CLOSED,
    AlertStatus.RESOLVED,
  ];

  public static readonly DegreeCelsius = '°C';
  public static readonly DegreeFahrenheit = '°F';
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertStatusType, AlertType } from './alert.enum';
import {
  AlertRemarkViewModel,
  AlertStatusMappingViewModel,
  AlertViewModel,
} from './alert-view.model';
import { CoreConstants } from '../../constants/core-constants';
import {
  AlertModel,
  AlertRemarkModel,
  AlertStatusMappingModel,
  ValueLimit,
} from '@dpdhl-iot/api/backend';
import { AlertChartModel } from '../../models/alert-chart.model';
import { EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { celsiusToFahrenheit } from '../../global-functions/global.function';

@Injectable({
  providedIn: 'root',
})
export class AlertMapperService {
  constructor(private readonly translateService: TranslateService) {}

  private static getAlertStatusByStatusId(statusId: string): AlertStatusType {
    let status: AlertStatusType;
    switch (statusId) {
      case 'Open':
        status = AlertStatusType.Open;
        break;
      case 'Accepted':
        status = AlertStatusType.Accepted;
        break;
      case 'Resolving':
        status = AlertStatusType.Resolving;
        break;
      case 'Resolved':
        status = AlertStatusType.Resolved;
        break;
      case 'Deactivated':
        status = AlertStatusType.Deactivated;
        break;
      case 'ManuallyClosed':
        status = AlertStatusType.ManuallyClosed;
        break;
      default:
        status = AlertStatusType.All;
        break;
    }
    return status;
  }

  private static getAlertType(alertType: string | undefined): string {
    if (alertType) {
      return alertType.charAt(0).toUpperCase() + alertType.slice(1);
    }
    return '';
  }

  toAlertViewModel(
    alertModel: AlertModel,
    unitSystem: EnvironmentalUnitSystem,
    removeValuePrefixes = false,
  ): AlertViewModel {
    const alertType = AlertMapperService.getAlertType(alertModel.alertType!);
    const alertTypeText = this.translateService.instant('room.chart.' + alertType.toLowerCase());
    const statusId = AlertMapperService.getAlertStatusByStatusId(alertModel.statusId!.toString());
    const threshold = this.getAlertThreshold(
      alertModel.threshold!,
      alertType,
      alertModel.limit!,
      unitSystem,
      !removeValuePrefixes,
    );
    const severityText = this.getAlertSeverity(alertModel.severity!);
    const alertStatusMappings = alertModel.alertStatusMappings?.map((alertStatus) =>
      this.toAlertStatusMappingViewModel(alertStatus, unitSystem),
    );
    const alertRemarks = alertModel.alertRemarks?.map((a) =>
      this.toAlertRemarkViewModel(a, unitSystem),
    );

    return {
      id: alertModel.id!,
      applicationId: alertModel.applicationId!,
      deviceId: alertModel.deviceId!,
      deviceName: alertModel.deviceName!,
      deviceTypeId: alertModel.deviceTypeId!,
      location: alertModel.location!,
      alertType,
      severity: alertModel.severity!,
      threshold,
      statusId,
      userName: alertModel.userName!,
      facilityName: alertModel.facilityName!,
      facilityId: alertModel.facilityId!,
      countryName: alertModel.countryName!,
      areaId: alertModel.areaId!,
      deviceTimestamp: alertModel.deviceTimestamp!,
      limit: alertModel.limit!,
      severityText,
      alertTypeText,
      alertStatusMappings,
      alertRemarks,
    };
  }

  toAlertChartModel(alertModel: AlertModel): AlertChartModel[] {
    const alertType = AlertMapperService.getAlertType(alertModel.alertType!);

    return (
      alertModel.alertRemarks?.map((remark) => {
        const statusId = AlertMapperService.getAlertStatusByStatusId(remark.statusId!.toString());
        return {
          alertType,
          statusId,
          deviceTimestamp: remark.timestamp!,
          deviceId: alertModel.deviceId!,
        };
      }) ?? []
    );
  }

  toAlertRemarkViewModel(
    alertRemarkModel: AlertRemarkModel,
    unitSystem: EnvironmentalUnitSystem,
  ): AlertRemarkViewModel {
    return {
      statusId: AlertMapperService.getAlertStatusByStatusId(alertRemarkModel.statusId!.toString()),
      userName: alertRemarkModel.userName!,
      alertId: alertRemarkModel.alertId!,
      alert: alertRemarkModel.alert
        ? this.toAlertViewModel(alertRemarkModel.alert, unitSystem)
        : undefined,
      remarks: alertRemarkModel.remarks!,
      messageId: alertRemarkModel.messageId!,
      timestamp: alertRemarkModel.timestamp!,
      id: alertRemarkModel.id!,
      additionalData: alertRemarkModel.additionalData,
    };
  }

  toAlertStatusMappingViewModel(
    alertStatusMappingModel: AlertStatusMappingModel,
    unitSystem: EnvironmentalUnitSystem,
  ): AlertStatusMappingViewModel {
    return {
      statusId: AlertMapperService.getAlertStatusByStatusId(
        alertStatusMappingModel.statusId!.toString(),
      ),
      userName: alertStatusMappingModel.userName!,
      alertId: alertStatusMappingModel.alertId!,
      alert: alertStatusMappingModel.alert
        ? this.toAlertViewModel(alertStatusMappingModel.alert, unitSystem)
        : undefined,
      messageId: alertStatusMappingModel.messageId!,
      timestamp: alertStatusMappingModel.timestamp!,
      id: alertStatusMappingModel.id!,
      adId: alertStatusMappingModel.adId!,
    };
  }

  private getAlertThreshold(
    threshold: string,
    alertType: string,
    limit: ValueLimit,
    unitSystem: EnvironmentalUnitSystem,
    addValuePrefixes = true,
  ): string {
    if (
      alertType === AlertType.Temperature &&
      unitSystem === EnvironmentalUnitSystem.IMPERIAL &&
      !isNaN(Number(threshold))
    ) {
      threshold = celsiusToFahrenheit(threshold).toString();
    }

    let thresholdResult = threshold;
    if (alertType !== CoreConstants.ERRORCODE && addValuePrefixes) {
      switch (limit) {
        case ValueLimit.LOWER:
          thresholdResult = ' < ' + threshold;
          break;
        case ValueLimit.UPPER:
          thresholdResult = ' > ' + threshold;
          break;
        case ValueLimit.EQUALS:
          thresholdResult = ' = ' + threshold;
          break;
        case ValueLimit.NOT_EQUALS:
          thresholdResult = ' != ' + threshold;
          break;
      }
    }
    if (alertType === AlertType.Temperature) {
      thresholdResult =
        thresholdResult +
        this.translateService.instant('dashboard.alert.unit.degree_' + unitSystem);
    } else if (
      alertType === AlertType.Humid ||
      alertType === AlertType.Humidity ||
      alertType === AlertType.Battery
    ) {
      thresholdResult =
        thresholdResult + this.translateService.instant('dashboard.alert.unit.percentage');
    }
    return thresholdResult;
  }

  private getAlertSeverity(severity: string | undefined): string {
    if (severity) {
      return this.translateService.instant('alert.severity.' + severity.toLowerCase());
    } else {
      return this.translateService.instant('alert.severity.normal');
    }
  }
}

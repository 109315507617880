/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeviceListSensorType = 'Temperature' | 'Humidity' | 'Battery' | 'PalletWrapped' | 'CO2' | 'ParticulateMatter1_0' | 'ParticulateMatter2_5' | 'ParticulateMatter10' | 'SoC' | 'SoH' | 'Current' | 'ErrorCode';

export const DeviceListSensorType = {
    TEMPERATURE: 'Temperature' as DeviceListSensorType,
    HUMIDITY: 'Humidity' as DeviceListSensorType,
    BATTERY: 'Battery' as DeviceListSensorType,
    PALLET_WRAPPED: 'PalletWrapped' as DeviceListSensorType,
    CO2: 'CO2' as DeviceListSensorType,
    PARTICULATE_MATTER1_0: 'ParticulateMatter1_0' as DeviceListSensorType,
    PARTICULATE_MATTER2_5: 'ParticulateMatter2_5' as DeviceListSensorType,
    PARTICULATE_MATTER10: 'ParticulateMatter10' as DeviceListSensorType,
    SO_C: 'SoC' as DeviceListSensorType,
    SO_H: 'SoH' as DeviceListSensorType,
    CURRENT: 'Current' as DeviceListSensorType,
    ERROR_CODE: 'ErrorCode' as DeviceListSensorType
};


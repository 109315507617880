<ng-container *transloco="let t; read: 'table'">
  <div class="dpdhl-flex dpdhl-justify-end dpdhl-mb-16 dpdhl-space-x-16">
    <div *ngIf="actionRowConfig.showSearch" class="md:dpdhl-w-1/2 lg:dpdhl-w-1/3 2xl:dpdhl-w-1/4">
      <dpdhl-search [initialSearch]="initialSearch" (search)="onSearch($event)"></dpdhl-search>
    </div>

    <dpdhl-button
      *ngIf="actionRowConfig.showExport"
      (buttonClick)="openDownloadModal()"
      (mouseenter)="onHover(true)"
      (mouseleave)="onHover(false)"
      variant="secondary"
      form="button-icon"
      type="button"
    >
      <dpdhl-svg-icon [src]="downloadIcon" svgClass="dpdhl-w-20 dpdhl-h-20"></dpdhl-svg-icon>
    </dpdhl-button>
  </div>

  <ng-container *ngIf="isDownloadModalOpen">
    <dpdhl-modal (modalClose)="closeDownloadModal()" [modalTitle]="'table.titleDownloadModal' | transloco">
      <div class="dpdhl-space-y-24 dpdhl-pt-12 dpdhl-flex dpdhl-flex-col">
        <div class="dpdhl-gap-16 dpdhl-grid dpdhl-grid-cols-2">
          <div>
            <dpdhl-select
              [required]="true"
              [_formGroup]="formGroup"
              _formControlName="exportType"
              [label]="'tableExportTypes' | transloco"
            >
              <ng-container *ngFor="let type of exportTypes">
                <dpdhl-select-option [value]="type" [display]="type"></dpdhl-select-option>
              </ng-container>
            </dpdhl-select>
          </div>
          <div></div>

          <div>
            <dpdhl-select
              [required]="true"
              [_formGroup]="formGroup"
              _formControlName="pageRange"
              [label]="'tablePageRange' | transloco"
            >
              <ng-container *ngFor="let page of pageRange">
                {{ page }}
                <dpdhl-select-option [value]="page" [display]="page"></dpdhl-select-option>
              </ng-container>
            </dpdhl-select>
          </div>
        </div>

        <div class="dpdhl-flex dpdhl-justify-between dpdhl-items-end dpdhl-flex-grow">
          <dpdhl-button
            class="dpdhl-flex"
            data-testid="download-list-btn-cancel"
            [title]="'table.btnTitleCancel' | transloco"
            (buttonClick)="closeDownloadModal()"
            variant="secondary"
          >
            <span>{{ t('btnCancel') }}</span>
          </dpdhl-button>

          <dpdhl-button
            class="dpdhl-flex"
            type="submit"
            data-testid="download-list-btn-download"
            [title]="'btnTitleDownload' | transloco"
            [disabled]="formGroup.invalid"
            (buttonClick)="download()"
            variant="primary"
          >
            <span>{{ t('btnDownload') }}</span>
          </dpdhl-button>
        </div>
      </div>
    </dpdhl-modal>
  </ng-container>
</ng-container>

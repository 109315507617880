export interface LoadImage {
  imageData: ArrayBuffer | null;
  isLoading: boolean;
}

export interface CellCondition {
  cell: number;
  alertType: CellAlertType;
  alertTimestamp?: Date;
  cellGroupName?: string;
  cellGroupColor?: string;
}

export enum CellAlertType {
  None,
  Warning,
  Alert,
}

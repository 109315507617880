import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertRoutingModule } from './alert-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlertListComponent } from './alert-list/alert-list.component';
import { AlertWorkflowComponent } from './alert-list/alert-workflow/alert-workflow.component';
import {
  BreadcrumbsModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  DatePickerModule,
  LoadingModule,
  ModalModule,
  SelectModule,
  TabsModule,
  TextareaModule,
} from '@dpdhl/angular-shared-ui';
import { SharedModule } from '@dpdhl-iot/shared';
import { AlertHistoryComponent } from './alert-list/alert-history/alert-history.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TableModule } from '@dpdhl-iot/como-ui';
import { AlertListTableComponent } from './alert-list/alert-list-table/alert-list-table.component';

@NgModule({
  declarations: [
    AlertListComponent,
    AlertWorkflowComponent,
    AlertHistoryComponent,
    AlertListTableComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    TranslateModule,
    AlertRoutingModule,
    LoadingModule,
    CardModule,
    BreadcrumbsModule,
    SharedModule,
    ModalModule,
    CheckboxModule,
    ButtonModule,
    TextareaModule,
    SelectModule,
    TableModule,
    TabsModule,
    DatePickerModule,
    AngularSvgIconModule,
  ],
  exports: [AlertListComponent, AlertListTableComponent],
})
export class AlertModule {}

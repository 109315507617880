import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginationComponentDataService } from './pagination-component-data.service';
import { skip } from 'rxjs/operators';
import { PaginationService } from './pagination.service';
import { FormControl, FormGroup } from '@angular/forms';
import { PageChangeEvent, PageSizeOption } from './pagination.types';

@Component({
  selector: 'dpdhl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pageSize = 20;
  @Input() pageSizeOptions: PageSizeOption[] = [];
  @Input() totalElementCount!: number;
  @Input() initialPage?: number;

  @Output() pageChange: EventEmitter<PageChangeEvent> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();

  numberOfPages!: number;
  pages: number[] = [];
  abbreviatedStart = false;
  abbreviatedEnd = false;

  currentPage = 0;
  paginationForm = new FormGroup({
    pageSize: new FormControl<number>(this.pageSize, { nonNullable: true }),
  });

  private subs: Subscription[] = [];

  get elementStart() {
    const start = this.currentPage * this.pageSize + 1;
    if (start < 1) {
      return 1;
    }
    return start;
  }

  get elementEnd() {
    const end = this.currentPage * this.pageSize + this.pageSize;
    if (end > this.totalElementCount) {
      return this.totalElementCount;
    }
    return end;
  }

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly dataService: PaginationComponentDataService,
    private readonly paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      // skip initial value from data ervice
      this.dataService.page$.pipe(skip(1)).subscribe({
        next: (val: number) => {
          this.currentPage = val;
          this.handlePageChange(this.currentPage, this.pageSize);
          this.ref.markForCheck();
        },
      }),
      this.paginationForm.controls.pageSize.valueChanges.subscribe((value) => {
        this.handlePageSizeChange(value);
        this.ref.markForCheck();
      })
    );

    this.onPage(this.initialPage || 0);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialPage && !changes.initialPage?.firstChange) {
      this.onPage(changes.initialPage.currentValue);
    }

    if (changes.totalElementCount?.currentValue) {
      this.numberOfPages = Math.ceil(this.totalElementCount / this.pageSize);

      this.updatePageProperties();
    }

    if (changes.pageSize?.currentValue) {
      this.paginationForm.controls.pageSize.setValue(changes.pageSize.currentValue);
    }
  }

  onPrevious() {
    this.dataService.onPrevious();
  }

  onNext() {
    this.dataService.onNext();
  }

  onPage(page: number) {
    this.dataService.onPage(page);
  }

  private handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.numberOfPages = Math.ceil(this.totalElementCount / pageSize);
    this.handlePageChange(0, pageSize);
    this.onPage(0);
    this.pageSizeChange.emit(pageSize);
  }

  private handlePageChange(pageNumber: number, pageSize: number) {
    this.updatePageProperties();

    const pageChange = this.paginationService.getPageChange(pageNumber, pageSize);
    this.pageChange.emit(pageChange);
  }

  get disabledPrevious() {
    return this.currentPage === 0;
  }

  get disabledNext() {
    return this.currentPage === this.numberOfPages - 1;
  }

  private updatePageProperties() {
    const pages = [];

    let abbreviatedStart = false;
    let startIndex = 0;
    if (this.currentPage > 2 && this.numberOfPages - 6 > 0) {
      if (this.numberOfPages > 5) {
        abbreviatedStart = true;
      }
      if (this.currentPage > this.numberOfPages - 5) {
        startIndex = this.numberOfPages - 5;
      } else {
        startIndex = this.currentPage - 2;
      }
    }

    let abbreviatedEnd = false;
    let end = this.numberOfPages;
    if (startIndex + 6 < this.numberOfPages) {
      abbreviatedEnd = true;
      end = startIndex + 5;
    }

    for (let index = startIndex; index < end; index++) {
      pages.push(index);
    }

    this.pages = pages;
    this.abbreviatedEnd = abbreviatedEnd;
    this.abbreviatedStart = abbreviatedStart;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaginationStorageType } from '@dpdhl/angular-shared-ui';

@Injectable()
export class TableComponentDataService {
  private prefix = 'TABLE';

  private _parameters$: BehaviorSubject<Params | null> = new BehaviorSubject<Params | null>(null);

  constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
    this._parameters$.subscribe({
      next: (value: Params | null) => {
        if (value) {
          this.router.navigate([], {
            queryParams: value,
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      },
    });
  }

  setPrefix(prefix: string) {
    this.prefix = prefix;
  }

  updateParams(params: { searchValue?: string; page?: string }) {
    const prefixedParam: any = {};

    Object.entries(params).forEach(([key, value]) => {
      prefixedParam[this.prefix + '_' + key] = value;
    });

    this._parameters$.next({ ...this._parameters$.value, ...prefixedParam });
  }

  get initialSearchValue() {
    return (
      this.route.snapshot.queryParamMap.get(this.getPrefixedParameter('searchValue')) || undefined
    );
  }

  get initialPage() {
    const prefixedParamString = this.route.snapshot.queryParamMap.get(
      this.getPrefixedParameter('page')
    );

    if (prefixedParamString) {
      return +prefixedParamString;
    }
    return 0;
  }

  setPageSize(pageSize: number, storageType: PaginationStorageType) {
    const key = `${this.prefix}-PageSize`;

    if (storageType === PaginationStorageType.LOCAL_STORAGE) {
      localStorage.setItem(key, pageSize.toString());
    } else if (storageType === PaginationStorageType.SESSION_STORAGE) {
      sessionStorage.setItem(key, pageSize.toString());
    }
  }

  getPageSize(storageType: PaginationStorageType): number {
    const key = `${this.prefix}-PageSize`;
    let pageSizeString: string | null = '';

    if (storageType === PaginationStorageType.LOCAL_STORAGE) {
      pageSizeString = localStorage.getItem(key);
    } else if (storageType === PaginationStorageType.SESSION_STORAGE) {
      pageSizeString = sessionStorage.getItem(key);
    }

    return pageSizeString ? parseInt(pageSizeString) : 0;
  }

  private getPrefixedParameter(param: string) {
    return `${this.prefix}_${param}`;
  }
}

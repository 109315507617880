/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TriggerOperator = 'Greater' | 'Less' | 'GreaterEquals' | 'LessEquals' | 'Equals' | 'NotEquals';

export const TriggerOperator = {
    GREATER: 'Greater' as TriggerOperator,
    LESS: 'Less' as TriggerOperator,
    GREATER_EQUALS: 'GreaterEquals' as TriggerOperator,
    LESS_EQUALS: 'LessEquals' as TriggerOperator,
    EQUALS: 'Equals' as TriggerOperator,
    NOT_EQUALS: 'NotEquals' as TriggerOperator
};


<div>
  <ng-container *ngFor="let option of items; let i = index">
    <div [hidden]="currentIndex !== i">
      <ng-template *ngTemplateOutlet="option.templateRef"></ng-template>
    </div>
  </ng-container>
</div>
<div class="flex justify-center mt-24 space-x-12">
  <ng-container *ngFor="let option of items; let i = index">
    <button class="h-16 w-1/6" [disabled]="currentIndex === i" (click)="nextSlide()">
      <div
        class="border-2 h-0 rounded-4"
        [ngClass]="{
          'border-gray-600': currentIndex === i,
          'border-gray-100 hover:border-gray-200': currentIndex !== i,
        }"
      ></div>
    </button>
  </ng-container>
</div>

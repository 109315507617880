import { GraphFilter } from './graph-data-request.interface';

export interface GraphDataAggregationRequest {
  filter: GraphFilter;
  aggregateBy: AggregateBy[];
  aggregationTime: number;
}

export interface AggregateBy {
  propertyPath: string;
  aggregation: Aggregation;
}

export enum Aggregation {
  Sum,
  Min,
  Max,
  Average,
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { utils as XLSXUtils, WorkBook, WorkSheet, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TableComponentDataService } from '../table-component-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DPDHLTable } from '../../como-ui.types';
import { DownloadReportType } from '../table.types';

@Component({
  selector: 'dpdhl-table-action-row',
  templateUrl: './table-action-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionRowComponent implements OnInit {
  @Input() actionRowConfig: DPDHLTable.ActionRowConfig = { showExport: false, showSearch: false };
  @Input() tableData: { [key: string]: string }[] = [];
  @Input() downloadConfig: DPDHLTable.DownloadConfig = {};

  @Output() searchListByValue: EventEmitter<string | null> = new EventEmitter();

  formGroup!: UntypedFormGroup;
  pageRange: string[] = ['All'];
  isHover = false;
  isDownloadModalOpen = false;
  exportTypes: DownloadReportType[] = [];

  initialSearch?: string;

  constructor(
    private readonly dataService: TableComponentDataService,
    private readonly fb: UntypedFormBuilder
  ) {
    this.initialSearch = this.dataService.initialSearchValue;
  }

  ngOnInit(): void {
    this.exportTypes = Object.keys(DownloadReportType).map((key) => {
      return (<any>DownloadReportType)[key];
    });

    this.formGroup = this.fb.group({
      exportType: [this.exportTypes[1], [Validators.required]],
      pageRange: [this.pageRange[0], [Validators.required]],
    });
  }

  get downloadIcon() {
    return this.isHover
      ? 'assets/icons/download-file--hover.svg'
      : 'assets/icons/download-file.svg';
  }

  onSearch(value: string | null) {
    this.searchListByValue.emit(value);
  }

  openDownloadModal(): void {
    this.isDownloadModalOpen = true;
  }

  closeDownloadModal(): void {
    this.isDownloadModalOpen = false;
  }

  download(): void {
    const type: string = this.formGroup.value.exportType;

    if (!type) {
      return;
    }

    if (type === DownloadReportType.PDF) {
      this.onDownloadPdfTable();
    } else if (type === DownloadReportType.EXCEL) {
      this.onDownloadExcelTable();
    }

    this.closeDownloadModal();
  }

  private onDownloadExcelTable(): void {
    let wb: WorkBook;
    let downLoadData = this.tableData;

    if (this.downloadConfig.toRowEntry) {
      // @ts-ignore
      downLoadData = this.tableData.map((entry) => this.downloadConfig.toRowEntry(entry));
    }

    const ws: WorkSheet = XLSXUtils.json_to_sheet(downLoadData, {
      header: this.downloadConfig.header || [],
      dateNF: this.downloadConfig.dateFormat,
    });
    wb = XLSXUtils.book_new();
    XLSXUtils.book_append_sheet(wb, ws, this.downloadConfig.sheetName || 'Data');

    writeFile(wb, `${this.downloadConfig.fileName || 'data'}.xlsx`);
  }

  private onDownloadPdfTable(): void {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'mm',
      orientation: 'l',
    });

    let downLoadData = this.tableData;

    if (this.downloadConfig.toRowEntry) {
      // @ts-ignore
      downLoadData = this.tableData.map((entry) => this.downloadConfig.toRowEntry(entry));
    }

    const head = [
      Object.keys(downLoadData[0]).map((title) => title.charAt(0).toUpperCase() + title.slice(1)),
    ];
    const body = downLoadData.map((dataSet) => Object.keys(dataSet).map((k) => dataSet[k]));

    doc.text(this.downloadConfig.fileName || 'Data', 14, 10);

    (doc as any).autoTable({
      head,
      body,
      theme: 'striped',
      showHead: 'firstPage',
      styles: {
        fontSize: 8,
      },
      headStyles: {
        fillColor: '#FFFFFF',
        textColor: '#000000e6',
        fontStyle: 'bold',
      },
      didParseCell: (data: any) => {
        this.parsedTableCellPdfDownload(data.doc, data.cell, data.column);
      },
    });

    doc.save(`${this.downloadConfig.fileName || 'data'}.pdf`);
  }

  onHover(isHover: boolean) {
    this.isHover = isHover;
  }

  private parsedTableCellPdfDownload(doc: any, cell: any, column: any) {
    if (cell === undefined) {
      return;
    }

    const hasCustomWidth = typeof cell.styles.cellWidth === 'number';

    if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
      return;
    }

    let text;

    if (cell.raw instanceof Node) {
      text = cell.raw.innerText;
    } else {
      if (typeof cell.raw == 'object') {
        return;
      } else {
        text = '' + cell.raw;
      }
    }

    // split cell string by spaces
    const words = text.split(/\s+/);

    // calculate longest word width
    const maxWordUnitWidth = words
      .map((s: any) => Math.floor(doc.getStringUnitWidth(s) * 100) / 100)
      .reduce((a: any, b: any) => Math.max(a, b), 0);
    const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor);

    const minWidth = cell.padding('horizontal') + maxWordWidth;

    // update minWidth for cell & column

    if (minWidth > cell.minWidth) {
      cell.minWidth = minWidth;
    }

    if (cell.minWidth > cell.wrappedWidth) {
      cell.wrappedWidth = cell.minWidth;
    }

    if (cell.minWidth > column.minWidth) {
      column.minWidth = cell.minWidth;
    }

    if (column.minWidth > column.wrappedWidth) {
      column.wrappedWidth = column.minWidth;
    }
  }
}

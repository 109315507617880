/**
 * IOT.Data.API
 * API for retrieving telemetry data on the platform.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Aggregation = 'Sum' | 'Min' | 'Max' | 'Average';

export const Aggregation = {
    SUM: 'Sum' as Aggregation,
    MIN: 'Min' as Aggregation,
    MAX: 'Max' as Aggregation,
    AVERAGE: 'Average' as Aggregation
};


/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DayOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export const DayOfWeek = {
    SUNDAY: 'Sunday' as DayOfWeek,
    MONDAY: 'Monday' as DayOfWeek,
    TUESDAY: 'Tuesday' as DayOfWeek,
    WEDNESDAY: 'Wednesday' as DayOfWeek,
    THURSDAY: 'Thursday' as DayOfWeek,
    FRIDAY: 'Friday' as DayOfWeek,
    SATURDAY: 'Saturday' as DayOfWeek
};


<ng-container>
  <div *ngIf="!isLoading; else loading" class="inline-flex flex-col">
    <div class="flex">
      <div class="diagram relative">
        <img
          #frequencyDiagram
          [src]="frequencyDiagramSrc"
          [width]="frequencyDiagramWidth"
          [height]="frequencyDiagramHeight"
          (load)="onImageLoad()"
          alt="frequency-diagram"
        />

        <div
          class="frequency-range flex flex-col-reverse justify-between items-end mr-32 absolute -top-8 -bottom-8 text-body2"
          [ngClass]="frequencyRangeContainerClass"
          data-testid="frequencyRangeContainer"
        >
          <div *ngFor="let frequency of frequencyRange" class="relative leading-4">
            <span>{{ frequency }} Hz</span>
            <span class="line w-12 absolute top-1/2 -right-32 bg-gray-200"></span>
          </div>
        </div>
        <div
          class="belt-scale absolute w-full mt-16 text-body2"
          *ngIf="showXAxis"
          data-testid="xAxisSegments"
        >
          <ng-container *ngFor="let segment of beltSegments; let i = index">
            <div class="absolute" [ngStyle]="{ 'left.%': (i * 10000) / beltCells }">
              <span class="line h-12 absolute left-1/2 -top-16 bg-gray-200"></span>
              <span class="w-32 text-center absolute -left-16">{{ i * 100 }}</span>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="noise-range flex ml-32" *ngIf="showScale" data-testid="scale">
        <div class="noise-colors h-full"></div>
        <div class="flex justify-between flex-col -my-12">
          <div class="scale-element relative">
            <span class="line absolute top-1/2 bg-gray-200"></span>
            <dpdhl-svg-icon
              class="block"
              src="assets/icons/volume-high.svg"
              svgClass="w-24 h-24"
            ></dpdhl-svg-icon>
          </div>
          <div class="scale-element relative">
            <span class="line absolute top-1/2 bg-gray-200"></span>
            <dpdhl-svg-icon
              class="block"
              src="assets/icons/volume-low.svg"
              svgClass="w-24 h-24"
            ></dpdhl-svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      class="legend flex justify-between mt-12 opacity-60 font-semibold"
      *ngIf="showXAxis"
      data-testid="xAxisLegend"
    >
      <span>{{ 'predictiveMaintenance.frequencyAnalysis.frequency' | translate }}</span>
      <span>{{ 'predictiveMaintenance.frequencyAnalysis.cellNumber' | translate }}</span>
      <span>{{ 'predictiveMaintenance.frequencyAnalysis.volume' | translate }}</span>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <dpdhl-skeleton-card>
    <div></div>
  </dpdhl-skeleton-card>
</ng-template>

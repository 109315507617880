<ng-container *ngFor="let column of tableColumns">
  <div data-testid="table-cell" class="dpdhl-py-8 dpdhl-truncate dpdhl-h-full">
    <ng-container [ngSwitch]="column.cellType">
      <ng-container *ngSwitchCase="'expander'">
        <div data-testid="table-expander-cell" class="dpdhl-h-full">
          <dpdhl-button
            *ngIf="showExpander(row, column.field)"
            class="flex"
            (buttonClick)="onRowExpand(row)"
            form="ghost"
          >
            <dpdhl-svg-icon
              *ngIf="!row.expanded"
              src="/assets/icons/chevron-forward.svg"
              svgClass="dpdhl-w-16 dpdhl-h-16"
            ></dpdhl-svg-icon>
            <dpdhl-svg-icon
              *ngIf="row.expanded"
              src="/assets/icons/chevron-down.svg"
              svgClass="dpdhl-w-16 dpdhl-h-16"
            ></dpdhl-svg-icon>
          </dpdhl-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'custom'">
        <ng-template *ngTemplateOutlet="column.customTemplate; context: { row: row }"></ng-template>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span [title]="getCell(row, column.field)">
          {{ getCell(row, column.field) }}
        </span>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'pagination'">
  <div class="dpdhl-flex dpdhl-items-center dpdhl-justify-between">
    <div *ngIf="pageSizeOptions.length > 0">
      <dpdhl-select
        _formControlName="pageSize"
        [_formGroup]="paginationForm"
        [clearable]="false"
        [searchable]="false"
        label="Number of rows"
      >
        <ng-container *ngFor="let option of pageSizeOptions">
          <dpdhl-select-option
            [value]="option.value"
            [display]="option.label"
          ></dpdhl-select-option>
        </ng-container>
      </dpdhl-select>
    </div>
    <div>
      <p class="dpdhl-text-tertiary">
        {{ t('resultSize', {elementStart, elementEnd, elementCount: totalElementCount}) }}
      </p>
    </div>
    <div class="dpdhl-flex dpdhl-items-center">
      <dpdhl-button
        form="ghost"
        variant="secondary"
        data-testid="previous"
        [disabled]="disabledPrevious"
        (buttonClick)="onPrevious()"
        class="dpdhl-items-center dpdhl-inline-flex"
      >
        <dpdhl-svg-icon
          src="/assets/icons/chevron-back.svg"
          [svgClass]="'svg-icon'"
        ></dpdhl-svg-icon>
      </dpdhl-button>

      <ng-container *ngIf="abbreviatedStart">
        <dpdhl-button
          form="ghost"
          [attr.data-testid]="'page' + 0"
          variant="secondary"
          (buttonClick)="onPage(0)"
        >
          <span
            class="dpdhl-border-b dpdhl-border-white dpdhl-p-12"
            [ngClass]="{ 'active-page': currentPage === 0 }"
          >
            {{ 1 }}
          </span>
        </dpdhl-button>
        <div>...</div>
      </ng-container>
      <ng-container *ngFor="let page of pages">
        <dpdhl-button
          form="ghost"
          [attr.data-testid]="'page' + page"
          variant="secondary"
          (buttonClick)="onPage(page)"
        >
          <span
            class="dpdhl-border-b dpdhl-border-white dpdhl-p-12"
            [ngClass]="{ 'active-page': currentPage === page }"
          >
            {{ page + 1 }}
          </span>
        </dpdhl-button>
      </ng-container>
      <ng-container *ngIf="abbreviatedEnd">
        <div>...</div>
        <dpdhl-button
          form="ghost"
          [attr.data-testid]="'page' + (numberOfPages - 1)"
          variant="secondary"
          (buttonClick)="onPage(numberOfPages - 1)"
        >
          <span
            class="dpdhl-border-b dpdhl-border-white dpdhl-p-12"
            [ngClass]="{ 'active-page': currentPage === numberOfPages - 1 }"
          >
            {{ numberOfPages }}
          </span>
        </dpdhl-button>
      </ng-container>

      <dpdhl-button
        form="ghost"
        variant="secondary"
        data-testid="next"
        [disabled]="disabledNext"
        (buttonClick)="onNext()"
        class="dpdhl-items-center dpdhl-inline-flex"
      >
        <dpdhl-svg-icon
          src="/assets/icons/chevron-forward.svg"
          [svgClass]="'svg-icon'"
        ></dpdhl-svg-icon>
      </dpdhl-button>
    </div>
  </div>
</ng-container>

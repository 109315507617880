/**
 * IOT.Management.API
 * API for managing applications on the platform.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BulkChangeResponse } from '../model/bulk-change-response';
// @ts-ignore
import { CreateDeviceRequest } from '../model/create-device-request';
// @ts-ignore
import { CursorPaginatedResultDeviceModel } from '../model/cursor-paginated-result-device-model';
// @ts-ignore
import { DeviceModel } from '../model/device-model';
// @ts-ignore
import { DeviceUpsertModel } from '../model/device-upsert-model';
// @ts-ignore
import { FilterRequestModel } from '../model/filter-request-model';
// @ts-ignore
import { GetAccessGroup404Response } from '../model/get-access-group404-response';
// @ts-ignore
import { UpdateDeviceRequest } from '../model/update-device-request';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ManagementConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DeviceService {

    protected basePath = 'https://api.platform.iot.dhl.com/management';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ManagementConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ManagementConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param xApiVersion
     * @param createDeviceRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDevice(xApiVersion: string, createDeviceRequest?: CreateDeviceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public createDevice(xApiVersion: string, createDeviceRequest?: CreateDeviceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public createDevice(xApiVersion: string, createDeviceRequest?: CreateDeviceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public createDevice(xApiVersion: string, createDeviceRequest?: CreateDeviceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling createDevice.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<DeviceModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createDeviceRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id
     * @param xApiVersion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deassociateFromDeviceAccessGroup(id: string, xApiVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public deassociateFromDeviceAccessGroup(id: string, xApiVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public deassociateFromDeviceAccessGroup(id: string, xApiVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public deassociateFromDeviceAccessGroup(id: string, xApiVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deassociateFromDeviceAccessGroup.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling deassociateFromDeviceAccessGroup.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/deassociate`;
        return this.httpClient.request<DeviceModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id
     * @param xApiVersion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDevice(id: string, xApiVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public deleteDevice(id: string, xApiVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public deleteDevice(id: string, xApiVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public deleteDevice(id: string, xApiVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDevice.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling deleteDevice.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DeviceModel>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xApiVersion
     * @param filterRequestModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public filterDevices(xApiVersion: string, filterRequestModel?: FilterRequestModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CursorPaginatedResultDeviceModel>;
    public filterDevices(xApiVersion: string, filterRequestModel?: FilterRequestModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CursorPaginatedResultDeviceModel>>;
    public filterDevices(xApiVersion: string, filterRequestModel?: FilterRequestModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CursorPaginatedResultDeviceModel>>;
    public filterDevices(xApiVersion: string, filterRequestModel?: FilterRequestModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling filterDevices.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/filter`;
        return this.httpClient.request<CursorPaginatedResultDeviceModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: filterRequestModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xApiVersion
     * @param take
     * @param cursor
     * @param computePageStats
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDevices(xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CursorPaginatedResultDeviceModel>;
    public getAllDevices(xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CursorPaginatedResultDeviceModel>>;
    public getAllDevices(xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CursorPaginatedResultDeviceModel>>;
    public getAllDevices(xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling getAllDevices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'take');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (computePageStats !== undefined && computePageStats !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>computePageStats, 'computePageStats');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<CursorPaginatedResultDeviceModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param applicationId
     * @param xApiVersion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApplicationDeviceTypes(applicationId: string, xApiVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<string>>;
    public getApplicationDeviceTypes(applicationId: string, xApiVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<string>>>;
    public getApplicationDeviceTypes(applicationId: string, xApiVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<string>>>;
    public getApplicationDeviceTypes(applicationId: string, xApiVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getApplicationDeviceTypes.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling getApplicationDeviceTypes.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/applications/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/device-types`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id
     * @param xApiVersion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevice(id: string, xApiVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public getDevice(id: string, xApiVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public getDevice(id: string, xApiVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public getDevice(id: string, xApiVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDevice.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling getDevice.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DeviceModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param applicationId
     * @param xApiVersion
     * @param take
     * @param cursor
     * @param computePageStats
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CursorPaginatedResultDeviceModel>;
    public getDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CursorPaginatedResultDeviceModel>>;
    public getDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CursorPaginatedResultDeviceModel>>;
    public getDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getDevicesByApplicationId.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling getDevicesByApplicationId.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'take');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (computePageStats !== undefined && computePageStats !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>computePageStats, 'computePageStats');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/applications/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/devices`;
        return this.httpClient.request<CursorPaginatedResultDeviceModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param applicationId
     * @param xApiVersion
     * @param take
     * @param cursor
     * @param computePageStats
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFreeDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CursorPaginatedResultDeviceModel>;
    public getFreeDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CursorPaginatedResultDeviceModel>>;
    public getFreeDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CursorPaginatedResultDeviceModel>>;
    public getFreeDevicesByApplicationId(applicationId: string, xApiVersion: string, take?: number, cursor?: string, computePageStats?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getFreeDevicesByApplicationId.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling getFreeDevicesByApplicationId.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'take');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (computePageStats !== undefined && computePageStats !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>computePageStats, 'computePageStats');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/applications/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/freedevices`;
        return this.httpClient.request<CursorPaginatedResultDeviceModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id
     * @param deviceAccessGroupId
     * @param xApiVersion
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public migrateDeviceToDeviceAccessGroup(id: string, deviceAccessGroupId: string, xApiVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public migrateDeviceToDeviceAccessGroup(id: string, deviceAccessGroupId: string, xApiVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public migrateDeviceToDeviceAccessGroup(id: string, deviceAccessGroupId: string, xApiVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public migrateDeviceToDeviceAccessGroup(id: string, deviceAccessGroupId: string, xApiVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling migrateDeviceToDeviceAccessGroup.');
        }
        if (deviceAccessGroupId === null || deviceAccessGroupId === undefined) {
            throw new Error('Required parameter deviceAccessGroupId was null or undefined when calling migrateDeviceToDeviceAccessGroup.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling migrateDeviceToDeviceAccessGroup.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/migrate/${this.configuration.encodeParam({name: "deviceAccessGroupId", value: deviceAccessGroupId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<DeviceModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id
     * @param xApiVersion
     * @param updateDeviceRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDevice(id: string, xApiVersion: string, updateDeviceRequest?: UpdateDeviceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DeviceModel>;
    public updateDevice(id: string, xApiVersion: string, updateDeviceRequest?: UpdateDeviceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DeviceModel>>;
    public updateDevice(id: string, xApiVersion: string, updateDeviceRequest?: UpdateDeviceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DeviceModel>>;
    public updateDevice(id: string, xApiVersion: string, updateDeviceRequest?: UpdateDeviceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateDevice.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling updateDevice.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DeviceModel>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateDeviceRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param applicationId
     * @param xApiVersion
     * @param deviceUpsertModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upsertDevicesByApplicationId(applicationId: string, xApiVersion: string, deviceUpsertModel?: Array<DeviceUpsertModel>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BulkChangeResponse>;
    public upsertDevicesByApplicationId(applicationId: string, xApiVersion: string, deviceUpsertModel?: Array<DeviceUpsertModel>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BulkChangeResponse>>;
    public upsertDevicesByApplicationId(applicationId: string, xApiVersion: string, deviceUpsertModel?: Array<DeviceUpsertModel>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BulkChangeResponse>>;
    public upsertDevicesByApplicationId(applicationId: string, xApiVersion: string, deviceUpsertModel?: Array<DeviceUpsertModel>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling upsertDevicesByApplicationId.');
        }
        if (xApiVersion === null || xApiVersion === undefined) {
            throw new Error('Required parameter xApiVersion was null or undefined when calling upsertDevicesByApplicationId.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiVersion !== undefined && xApiVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-api-version', String(xApiVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (IoTCommonPlatform) required
        localVarCredential = this.configuration.lookupCredential('IoTCommonPlatform');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/applications/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/devices`;
        return this.httpClient.request<BulkChangeResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deviceUpsertModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  getAlertStatusChipColor,
  getAlertStatusIdByStatus,
  getNextWorkflowStatus,
} from '@dpdhl-iot/alert';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AlertActionType,
  AlertRemarkViewModel,
  AlertStatusType,
  AlertViewModel,
  UpdateAlertStatusRequestArgs,
  ViewConfigurationModel,
  ViewConfigurationService,
} from '@dpdhl-iot/shared';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-workflow',
  templateUrl: './alert-workflow.component.html',
  styleUrls: ['./alert-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertWorkflowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() open = false;
  @Input() alert: AlertViewModel | undefined = undefined;
  @Input() action = AlertActionType.View;
  @Output() closeDialog = new EventEmitter();
  @Output() updateAlertStatus = new EventEmitter<UpdateAlertStatusRequestArgs>();

  workflowFormGroup: FormGroup;
  isCollapsed = true;
  protected alertRemarks: AlertRemarkViewModel[] = [];
  private viewConfiguration: ViewConfigurationModel;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly viewConfigurationService: ViewConfigurationService,
  ) {}

  get noFindingsApplicable() {
    return this.action === AlertActionType.Close && this.viewConfiguration.alert.showNoFindings;
  }
  get isTncRequired() {
    return [AlertActionType.Accept, AlertActionType.Resolving, AlertActionType.Close].includes(
      this.action,
    );
  }

  get isCommentRequired() {
    return [AlertActionType.Comment, AlertActionType.Resolving, AlertActionType.Close].includes(
      this.action,
    );
  }

  get enableCommenting() {
    return [
      AlertActionType.Comment,
      AlertActionType.Accept,
      AlertActionType.Resolving,
      AlertActionType.Close,
    ].includes(this.action);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.viewConfigurationService.currentViewConfiguration$.subscribe(
        (config) => (this.viewConfiguration = config),
      ),
    );

    this.workflowFormGroup = new FormGroup({
      tncCheckbox: new FormControl(false, [Validators.requiredTrue]),
      commentBox: new FormControl('', [Validators.required]),
      noFindingsCheckbox: new FormControl(false),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alert && !changes.alert.isFirstChange()) {
      this.initializeWorkflowDialog();
      this.isCollapsed = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initializeWorkflowDialog() {
    this.addFormValidators();
    this.updateAlertStates();
  }

  saveWorkflow(): void {
    if (!this.alert) {
      return;
    }
    const currentAlertStatus = getAlertStatusIdByStatus(this.alert.statusId);
    const changedAlertStatus = getNextWorkflowStatus(currentAlertStatus, this.action);
    let additionalData: { [key: string]: string } | null = null;
    if (this.workflowFormGroup.value.noFindingsCheckbox === true) {
      additionalData = {};
      additionalData.NoFindings = true.toString();
    }
    this.updateAlertStatus.emit({
      alert: this.alert,
      request: {
        currentAlertStatus,
        changedAlertStatus,
        comment: this.workflowFormGroup.value.commentBox,
        additionalData: additionalData,
      },
      action: this.action,
    });
  }

  getAlertStatusChipColor(statusId: AlertStatusType): string {
    return getAlertStatusChipColor(statusId);
  }

  private addFormValidators() {
    this.workflowFormGroup.clearValidators();
    this.workflowFormGroup.controls.tncCheckbox.addValidators(
      this.isTncRequired ? Validators.requiredTrue : [],
    );
    this.workflowFormGroup.controls.commentBox.addValidators(
      this.isCommentRequired ? Validators.required : [],
    );
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.updateAlertStates();
  }

  updateAlertStates() {
    if (!this.alert?.alertRemarks) {
      this.alertRemarks = [];
      return;
    }

    if (this.isCollapsed) {
      this.alertRemarks = this.alert.alertRemarks.slice(0, 2);
    } else {
      this.alertRemarks = this.alert.alertRemarks;
    }
  }

  noFindingsChecked(checked?: boolean) {
    if (checked && this.workflowFormGroup.value.commentBox.length === 0) {
      this.workflowFormGroup.controls.commentBox.setValue(
        this.translateService.instant('predictiveMaintenance.common.noFindings'),
      );
    }
  }

  protected readonly JSON = JSON;
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { LoadImage } from '../predictive-maintenance.types';

@Component({
  selector: 'app-frequency-diagram',
  templateUrl: './frequency-diagram.component.html',
  styleUrls: ['./frequency-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrequencyDiagramComponent implements OnInit, OnChanges {
  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly ref: ChangeDetectorRef,
  ) {}

  @ViewChild('frequencyDiagram') frequencyDiagram!: ElementRef<HTMLImageElement>;

  /**
   * number of cells on the sorter
   */
  @Input() beltCells = 0;

  /**
   * array of frequency values. represents the frequency range of the provided data
   */
  @Input() frequencyRange: string[] = [];

  /**
   * Observable for the frequency diagram
   * The default dimensions are 1000x384 but the component will stretch/shrink itself according to the dimensions of the image
   */
  @Input() loadFrequencyDiagram?: Observable<LoadImage>;

  /**
   * show or hide the volume scale on the right side
   */
  @Input() showScale = true;

  /**
   * show or hide the x-axis, including legend
   */
  @Input() showXAxis = true;

  /**
   * set additional class for the frequency range container
   */
  @Input() frequencyRangeContainerClass = '';

  public beltSegments = new Array(0);
  public frequencyDiagramSrc: SafeUrl = '';
  public frequencyDiagramWidth = 1000;
  public frequencyDiagramHeight = 384;
  public isLoading = false;

  public ngOnInit(): void {
    this.loadFrequencyDiagram?.subscribe(({ imageData, isLoading }) => {
      if (imageData) {
        this.frequencyDiagramSrc = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(new Blob([imageData])),
        );
      }
      this.isLoading = isLoading;
      this.ref.markForCheck();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.beltCells) {
      this.beltSegments = new Array(Math.floor(this.beltCells / 100) + 1);
    }
  }

  public onImageLoad(): void {
    this.frequencyDiagramWidth = this.frequencyDiagram.nativeElement.naturalWidth;
    this.frequencyDiagramHeight = this.frequencyDiagram.nativeElement.naturalHeight;
  }
}

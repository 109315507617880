<dpdhl-table
  [data]="{ elements: alertData }"
  [loading]="isLoading"
  [downloadConfig]="downloadConfig"
  [sorting]="sortingConfig"
  [withActionRow]="{
    showSearch: true,
    showExport: viewConfiguration.alert.showExport,
  }"
  prefix="alerts"
>
  <ng-container *ngIf="viewConfiguration.alert.showColumn_Device">
    <dpdhl-table-column
      field="deviceId"
      header="{{ 'dashboard.alert.deviceId' | translate }}"
      cellType="custom"
      [customTemplate]="customDeviceId"
    >
      <ng-template #customDeviceId let-alert="row">
        <a
          [ngClass]="
            selectableFacilities.includes(alert.facilityId)
              ? 'pointer-events-auto'
              : 'pointer-events-none'
          "
          routerLink="{{
            [
              '/use-cases/' +
                viewConfiguration.application.id +
                '/facilities/' +
                alert.facilityId +
                '/' +
                alert.areaId,
            ]
          }}"
        >
          <span> {{ alert.deviceId }}</span></a
        >
      </ng-template>
    </dpdhl-table-column>
    <dpdhl-table-column
      field="deviceName"
      header="{{ 'dashboard.alert.deviceName' | translate }}"
    ></dpdhl-table-column>
  </ng-container>
  <dpdhl-table-column
    field="countryName"
    *ngIf="viewConfiguration.alert.showColumn_Country"
    header="{{ 'dashboard.filter.country' | translate }}"
    [sortConfig]="'Text' | sortingConfig"
  ></dpdhl-table-column>
  <dpdhl-table-column
    field="location"
    header="{{ viewConfiguration.areaView.localizations.area | translate }}"
    cellType="custom"
    [customTemplate]="customLocation"
    *ngIf="showAreaLink && viewConfiguration.alert.showColumn_Location"
  >
    <ng-template #customLocation let-alert="row">
      <a
        [ngClass]="
          selectableFacilities.includes(alert.facilityId)
            ? 'pointer-events-auto'
            : 'pointer-events-none'
        "
        routerLink="{{
          [
            '/use-cases/' +
              viewConfiguration.application.id +
              '/facilities/' +
              alert.facilityId +
              '/' +
              alert.areaId,
          ]
        }}"
      >
        <span [title]="alert.location">{{ alert.location }}</span></a
      >
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="facilityName"
    header="{{ 'dashboard.alert.facility' | translate }}"
    cellType="custom"
    [customTemplate]="customFacility"
    *ngIf="!showAreaLink && viewConfiguration.alert.showColumn_FacilityName"
  >
    <ng-template #customFacility let-alert="row">
      <a
        [ngClass]="
          selectableFacilities.includes(alert.facilityId)
            ? 'pointer-events-auto'
            : 'pointer-events-none'
        "
        routerLink="{{
          ['/use-cases/' + viewConfiguration.application.id + '/facilities/' + alert.facilityId]
        }}"
      >
        <span [title]="alert.facilityName">{{ alert.facilityName }}</span></a
      >
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="deviceTimestamp"
    header="{{ 'dashboard.alert.deviceTimestamp' | translate }}"
    [sortConfig]="'Timestamp' | sortingConfig"
    cellType="custom"
    [customTemplate]="customDate"
  >
    <ng-template #customDate let-alert="row">
      <span [title]="alert.deviceTimestamp | date: 'dd.MM.yyyy HH:mm'">{{
        alert.deviceTimestamp | date: 'dd.MM.yyyy HH:mm'
      }}</span>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="alertType"
    header="{{ 'dashboard.alert.alertType' | translate }}"
    cellType="custom"
    [customTemplate]="customAlertType"
  >
    <ng-template #customAlertType let-alert="row">
      <div *ngIf="alert.alertType" class="flex items-center space-x-8">
        <svg-icon
          svgClass="w-16 h-16"
          src="assets/images/CoMo/{{ alert.alertType.toLowerCase() }}-default-icon.svg"
        ></svg-icon>
        <span [title]="alert.alertTypeText">{{ alert.alertTypeText }}</span>
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="severity"
    header="{{ 'dashboard.alert.severity' | translate }}"
    [sortConfig]="severitySortConfig"
    cellType="custom"
    [customTemplate]="customSeverity"
  >
    <ng-template #customSeverity let-alert="row">
      <div *ngIf="alert.severity" class="flex items-center space-x-8">
        <img
          *ngIf="alert.severity?.toLowerCase() === 'high'"
          class="info"
          alt="info"
          style="width: 2px; height: 16px"
          src="assets/images/CoMo/severity-{{ alert.severity?.toLowerCase() }}-icon.png"
        />
        <span [title]="alert.severityText">{{ alert.severityText }}</span>
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="threshold"
    *ngIf="viewConfiguration.alert.showColumn_Threshold"
    header="{{ 'dashboard.alert.threshold' | translate }}"
  ></dpdhl-table-column>
  <dpdhl-table-column
    field="threshold"
    [sortConfig]="valueSortConfig"
    *ngIf="viewConfiguration.alert.showColumn_Value"
    header="{{ 'dashboard.alert.value' | translate }}"
  ></dpdhl-table-column>
  <dpdhl-table-column
    field="statusId"
    header="{{ 'dashboard.alert.status' | translate }}"
    cellType="custom"
    [customTemplate]="customStatus"
  >
    <ng-template #customStatus let-alert="row">
      <div class="flex space-x-4 items-center">
        <div
          class="dpdhl-rounded-8 text-center px-8 py-8 text-body2 {{
            getAlertStatusChipColor(alert.statusId)
          }}"
        ></div>
        <span class="dpdhl-chip-content">{{
          'dashboard.alert.' + alert.statusId.toLowerCase() | translate
        }}</span>
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="lastComment"
    header="{{ 'alert.filterCol.lastComment' | translate }}"
    [sortable]="false"
    cellType="custom"
    *ngIf="viewConfiguration.alert.showColumn_LastComment"
    [customTemplate]="lastComment"
  >
    <ng-template #lastComment let-alert="row">
      <span
        title="{{ alert.alertRemarks[0].remarks }}"
        class="break-normal"
        data-testid="spanLastComment"
      >
        {{ alert.alertRemarks[0].remarks }}
      </span>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="action"
    header="{{ 'device.grid.action' | translate }}"
    [sortable]="false"
    cellType="custom"
    [customTemplate]="customAction"
  >
    <ng-template #customAction let-alert="row">
      <div class="flex mt-4">
        <div
          class="cursor-pointer ml-12"
          title="{{ 'dashboard.alert.context.view' | translate }}"
          (click)="onActionClick({ action: AlertActionType.View, alert })"
        >
          <svg-icon src="/assets/images/CoMo/view-details.svg" svgClass="h-12"></svg-icon>
        </div>
        <ng-container *ngIf="allowWorkflowAction(alert) && allowAlertWorkflow(alert)">
          <ng-container *ngFor="let action of getActions(alert)">
            <div
              class="cursor-pointer ml-12"
              title="{{ 'dashboard.alert.context.' + action.toLowerCase() | translate }}"
              (click)="onActionClick({ action, alert })"
            >
              <svg-icon
                *ngIf="action === 'Comment'"
                src="/assets/images/CoMo/comments_alert.svg"
                svgClass="h-12"
              ></svg-icon>
              <svg-icon
                *ngIf="['Accept', 'Resolving'].includes(action)"
                src="/assets/images/CoMo/accept.svg"
                svgClass="h-12"
              ></svg-icon>
              <svg-icon
                *ngIf="action === 'Close'"
                src="/assets/images/CoMo/close_manually.svg"
                svgClass="h-12"
              ></svg-icon>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </dpdhl-table-column>
</dpdhl-table>
<ng-container *ngIf="alertData?.length > 0">
  <app-alert-workflow
    [alert]="alertAction?.alert"
    [action]="alertAction?.action"
    [open]="showWorkflowDialog"
    (closeDialog)="onCloseAlertStatus()"
    (updateAlertStatus)="onUpdateAlertStatus($event)"
  ></app-alert-workflow>
</ng-container>

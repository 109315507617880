<dpdhl-table
  [data]="{ elements: alertRules ?? [] }"
  [withActionRow]="enableSearch"
  [loading]="false"
>
  <dpdhl-table-column
    field="ruleName"
    header="{{ 'alertTemplates.ruleName' | translate }}"
    cellType="custom"
    [customTemplate]="ruleNameTemplate"
  >
    <ng-template #ruleNameTemplate let-data="row">
      <div [title]="data.triggerConditions.alertName">
        {{ data.triggerConditions.alertName }}
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="condition"
    header="{{ 'alertTemplates.condition' | translate }}"
    cellType="custom"
    [customTemplate]="conditionTemplate"
  >
    <ng-template #conditionTemplate let-data="row">
      <div class="flex space-x-4">
        <svg-icon
          *ngIf="data.category"
          svgClass="w-16 h-16 mt-4"
          src="assets/images/CoMo/{{ getParameterIcon(data.category) }}-default-icon.svg"
        ></svg-icon>
        <div [title]="getCondition(data)">
          {{ getCondition(data) }}
        </div>
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="severity"
    header="{{ 'alertTemplates.severity' | translate }}"
    cellType="custom"
    [customTemplate]="severityTemplate"
  >
    <ng-template #severityTemplate let-data="row">
      <div class="flex space-x-4">
        <img
          *ngIf="data.metaData['severity'] === 'High'"
          [alt]="data.metaData.severity"
          src="assets/images/CoMo/severity-high-icon.png"
          class="h-16 self-center"
        />
        <div [title]="data.metaData.severity">
          {{ data.metaData.severity }}
        </div>
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="executeOn"
    header="{{ 'alertTemplates.executeOn' | translate }}"
    cellType="custom"
    [customTemplate]="executeOnTemplate"
  >
    <ng-template #executeOnTemplate let-data="row">
      <div>
        {{
          data.daysOfWeekTimeFilter
            ? getExecuteOn(data.daysOfWeekTimeFilter)
            : ('alertTemplates.always' | translate)
        }}
      </div>
    </ng-template>
  </dpdhl-table-column>
  <dpdhl-table-column
    field="action"
    header="{{ 'device.grid.action' | translate }}"
    cellType="custom"
    [width]="'5rem'"
    [customTemplate]="actionTemplate"
    [sortable]="false"
    *ngIf="!viewOnly"
  >
    <ng-template #actionTemplate let-data="row">
      <div class="flex flex-row gap-8">
        <dpdhl-button
          form="ghost"
          title="{{ 'alertTemplates.action.editHint' | translate }}"
          (buttonClick)="onEditRule(data)"
        >
          <svg-icon src="/assets/icons/edit.svg" svgClass="w-16 h-16"></svg-icon>
        </dpdhl-button>
        <dpdhl-button
          form="ghost"
          title="{{ 'alertTemplates.action.deleteHint' | translate }}"
          data-test-id="btnDelete"
          (buttonClick)="onRemoveRule(data)"
        >
          <svg-icon src="/assets/icons/trash-bin-delete.svg" svgClass="w-16 h-16"></svg-icon>
        </dpdhl-button>
      </div>
    </ng-template>
  </dpdhl-table-column>
</dpdhl-table>

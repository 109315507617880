import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appCarouselOption]',
})
export class CarouselOptionDirective {
  @Input() appCarouselOption!: number;
  constructor(public templateRef: TemplateRef<any>) {}
}

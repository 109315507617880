/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AlertFrequency = 'Never' | 'Onetime' | 'Continuously' | 'FirstTimeOnly';

export const AlertFrequency = {
    NEVER: 'Never' as AlertFrequency,
    ONETIME: 'Onetime' as AlertFrequency,
    CONTINUOUSLY: 'Continuously' as AlertFrequency,
    FIRST_TIME_ONLY: 'FirstTimeOnly' as AlertFrequency
};


import { Injectable } from '@angular/core';
import { GraphDataTelemetry } from './interfaces/graph-data-telemetry.interface';
import { SensorType } from './enums/sensor-type.enum';
import {
  GraphDataAxis,
  GraphDataDevice,
  GraphSeries,
} from './interfaces/graph-data-device.interface';
import {
  AlertChartModel,
  celsiusToFahrenheit,
  CoreConstants,
  findNearestNumber,
  graphDashStyle,
  graphPointStyle,
  graphSeriesColor,
  LegendModel,
} from '@dpdhl-iot/shared';
import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';
import { ChartData, ChartDataset, Point } from 'chart.js';
import { AlertStatus } from '@dpdhl-iot/api/backend';
import { AggregateResponseModel } from '@dpdhl-iot/api/data';
import { EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';

@Injectable({
  providedIn: 'root',
})
export class GraphDataProcessorService {
  processGraphData(
    graphDataTelemetries: Map<string, Array<AggregateResponseModel & GraphDataTelemetry>>,
    sensorType: SensorType,
    unitSystem: EnvironmentalUnitSystem,
  ): GraphDataDevice[] {
    const graphDataDevices: GraphDataDevice[] = [];

    for (const deviceKey of graphDataTelemetries.keys()) {
      const graphDataAxis: GraphDataAxis[] = [];
      graphDataTelemetries.get(deviceKey)?.forEach((graphDataTelemetry) => {
        let yAxis = '';
        switch (sensorType) {
          case SensorType.Battery:
            yAxis = graphDataTelemetry.sensorMeasurements.batteryPercentage?.toString();
            break;
          case SensorType.CO2:
            yAxis = graphDataTelemetry.sensorMeasurements.carbonDioxideLevel?.toString();
            break;
          case SensorType.Current:
            yAxis = graphDataTelemetry.properties?.generalInformation?.current;
            break;
          case SensorType.Humidity:
            yAxis = graphDataTelemetry.sensorMeasurements.humidity?.toString();
            break;
          case SensorType.PalletWrapped:
          case SensorType.PalletAggregation:
            yAxis = graphDataTelemetry.properties?.machineCounters?.tot_completed_packing_cycles;
            break;
          case SensorType.Temperature:
            if (unitSystem === EnvironmentalUnitSystem.IMPERIAL) {
              yAxis = celsiusToFahrenheit(
                graphDataTelemetry.sensorMeasurements.temperature,
              ).toString();
            } else {
              yAxis = graphDataTelemetry.sensorMeasurements.temperature?.toString();
            }
            break;
          case SensorType.SoC:
            yAxis = graphDataTelemetry.properties?.generalInformation?.soC;
            break;
          case SensorType.SoH:
            yAxis = graphDataTelemetry.properties?.generalInformation?.soH;
            break;
          case SensorType.ParticulateMatter10:
            yAxis = graphDataTelemetry.properties?.particulateMatterConcentration10;
            break;
          case SensorType.ParticulateMatter2_5:
            yAxis = graphDataTelemetry.properties?.particulateMatterConcentration2_5;
            break;
          case SensorType.ParticulateMatter1_0:
            yAxis = graphDataTelemetry.properties?.particulateMatterConcentration1_0;
            break;
        }
        const y = parseFloat(yAxis);
        if (!isNaN(y)) {
          graphDataAxis.push({
            x: new Date(graphDataTelemetry.deviceTimestamp),
            y,
          });
        }
      });
      graphDataDevices.push({
        key: deviceKey,
        value: graphDataAxis,
      });
    }

    return graphDataDevices.filter((graphDataDevice) => graphDataDevice.value.length > 0);
  }

  aggregateGraphData(graphDataDevices: GraphDataDevice[]) {
    graphDataDevices.forEach((graphDataDevice) => {
      const maxDate = new Date(
        graphDataDevice.value
          .reduce((m, v, i) => (v.x > (m.x ?? i) ? v : m), graphDataDevice.value[0])
          .x.toDateString(),
      ).getTime();
      const minDate = new Date(
        graphDataDevice.value
          .reduce((m, v, i) => (v.x < (m.x ?? i) ? v : m), graphDataDevice.value[0])
          .x.toDateString(),
      ).getTime();

      const dates = [
        ...Array(Math.floor((maxDate - minDate) / CoreConstants.ONE_DAY_INTERVAL) + 1),
      ].map((_, i) => new Date(minDate + i * CoreConstants.ONE_DAY_INTERVAL));

      let latestValue = 0;
      const values: GraphDataAxis[] = new Array<GraphDataAxis>();
      dates.forEach((date) => {
        const valuesOfDate = graphDataDevice.value.filter(
          (a) => a.x.toDateString() === date.toDateString(),
        );
        if (valuesOfDate.length > 0) {
          latestValue = Math.max(...valuesOfDate.map((a) => a.y));
        }
        values.push({
          x: date,
          y: latestValue,
        });
      });

      graphDataDevice.value = values;
    });
  }

  getAlertAnnotations(
    datasets: ChartDataset<'line', Point[]>[],
    alertList: AlertChartModel[],
    sensorType: SensorType,
  ): Record<string, AnnotationOptions> {
    const annotations: Record<string, AnnotationOptions> = {};
    if (alertList.length && datasets.length && alertList.some((a) => a.alertType === sensorType)) {
      const alertSeries = datasets.filter((g) => !g.hidden);

      alertList.forEach((alert, index) => {
        const series = alertSeries.find((s) => s.label?.includes(alert.deviceId));
        if (series) {
          const nearestTime = findNearestNumber(
            series.data.map((r) => r.x),
            alert.deviceTimestamp,
          );
          const point = series.data.find((r) => r.x === nearestTime);
          if (point) {
            annotations[index] = this.getAlertAnnotation(alert, point, series);
          }
        }
      });
    }
    return annotations;
  }

  calculateGraphData(
    graphDataDevices: GraphDataDevice[],
    graphSeriesData: GraphSeries[],
    legendData: LegendModel[],
  ): ChartData<'line'> {
    graphDataDevices.forEach((graphData) => {
      const graphSeries = graphSeriesData.find(
        (seriesData) => seriesData.sensorId === graphData.key,
      );
      if (graphSeries) {
        graphSeries.readings.push(...graphData.value);
      } else {
        graphSeriesData.push(this.addGraphSeries(graphData));
      }
    });

    return {
      datasets: graphSeriesData.map((s, i) => {
        const legend = legendData.find((a) => a.id === s.sensorId);
        const color = legend?.color ?? graphSeriesColor(i);
        const pointStyle = legend?.markerShape ?? graphPointStyle(i);
        const dashStyle = legend?.dashArray ?? graphDashStyle(i);
        return {
          data: s.readings.map((r) => ({ x: r.x.getTime(), y: r.y })),
          label: s.sensorId,
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointStyle,
          borderDash: dashStyle,
          borderWidth: 1.25,
          pointRadius: 2.5,
          pointHoverRadius: 5,
          spanGaps: true,
          hidden: s.readings.length === 0,
        };
      }),
    };
  }

  protected addGraphSeries(graphDataDevice: GraphDataDevice): GraphSeries {
    return {
      readings: [...graphDataDevice.value].reverse(),
      sensorId: graphDataDevice.key,
    };
  }

  private getAlertAnnotation(
    alert: AlertChartModel,
    point: Point,
    series: ChartDataset<'line', Point[]>,
  ): AnnotationOptions {
    return {
      type: 'polygon',
      xValue: alert.deviceTimestamp,
      yValue: point.y,
      sides: 3,
      borderColor: series.borderColor?.toString(),
      borderWidth: 0.3,
      rotation: alert.statusId === AlertStatus.OPEN ? 0 : 60,
      backgroundColor:
        alert.statusId === AlertStatus.OPEN ? 'rgba(235, 19, 30, 0.5)' : 'rgba(178, 178, 178, 0.3)',
      radius: 10,
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PaginationComponentDataService {
  private _page$: BehaviorSubject<number> = new BehaviorSubject(0);

  get page$(): Observable<number> {
    return this._page$.asObservable();
  }

  onPrevious(): void {
    this._page$.next(this._page$.value - 1);
  }

  onNext(): void {
    this._page$.next(this._page$.value + 1);
  }

  onPage(page: number): void {
    this._page$.next(page);
  }
}

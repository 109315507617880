import { AlertsRequest, AlertStatus, Filter, FilterOperator } from '@dpdhl-iot/api/backend';
import { AlertActionType, AlertStatusType } from '@dpdhl-iot/shared';

export const getAlertStatusIdByStatus = (status: AlertStatusType): AlertStatus => {
  let statusId: AlertStatus;
  switch (status) {
    case AlertStatusType.All:
      statusId = AlertStatus.ALL;
      break;
    case AlertStatusType.Open:
      statusId = AlertStatus.OPEN;
      break;
    case AlertStatusType.Accepted:
      statusId = AlertStatus.ACCEPTED;
      break;
    case AlertStatusType.Resolving:
      statusId = AlertStatus.RESOLVING;
      break;
    case AlertStatusType.Resolved:
      statusId = AlertStatus.RESOLVED;
      break;
    case AlertStatusType.Deactivated:
      statusId = AlertStatus.ALL;
      break;
    case AlertStatusType.ManuallyClosed:
      statusId = AlertStatus.MANUALLY_CLOSED;
      break;
    default:
      statusId = AlertStatus.ALL;
      break;
  }
  return statusId;
};

export const getNextWorkflowStatus = (
  currentStatus: AlertStatus,
  currentContext: AlertActionType,
): AlertStatus => {
  let statusId: AlertStatus;

  switch (currentContext) {
    case AlertActionType.Accept:
      statusId = AlertStatus.ACCEPTED;
      break;
    case AlertActionType.Resolving:
      statusId = AlertStatus.RESOLVING;
      break;
    case AlertActionType.Close:
      statusId = AlertStatus.MANUALLY_CLOSED;
      break;
    default:
      statusId = currentStatus;
      break;
  }
  return statusId;
};

export const getAlertStatusChipColor = (statusId: AlertStatusType): string => {
  switch (statusId) {
    case AlertStatusType.Open:
      return 'dpdhl-bg-red-100';
    case AlertStatusType.Accepted:
      return 'dpdhl-bg-yellow-200';
    case AlertStatusType.Resolving:
      return 'dpdhl-bg-green-50';
    case AlertStatusType.Resolved:
    case AlertStatusType.ManuallyClosed:
      return 'dpdhl-bg-gray-100';
    case AlertStatusType.Deactivated:
    default:
      return 'dpdhl-bg-white';
  }
};

export const getContextMenusByAlertStatus = (status: AlertStatusType): AlertActionType[] => {
  let contextMenus: AlertActionType[];
  switch (status) {
    case AlertStatusType.All:
      contextMenus = [
        AlertActionType.Accept,
        AlertActionType.Resolving,
        AlertActionType.Close,
        AlertActionType.Comment,
        AlertActionType.View,
      ];
      break;
    case AlertStatusType.Open:
      contextMenus = [AlertActionType.Accept, AlertActionType.Close, AlertActionType.Comment];
      break;
    case AlertStatusType.Accepted:
      contextMenus = [
        AlertActionType.Resolving,
        AlertActionType.Close,
        AlertActionType.Comment,
        AlertActionType.View,
      ];
      break;
    case AlertStatusType.Resolving:
      contextMenus = [AlertActionType.Close, AlertActionType.Comment, AlertActionType.View];
      break;
    case AlertStatusType.Resolved:
    case AlertStatusType.Deactivated:
    case AlertStatusType.ManuallyClosed:
      contextMenus = [AlertActionType.Comment, AlertActionType.View];
      break;
    default:
      contextMenus = [];
      break;
  }
  return contextMenus;
};

export const getAlertStatusFiltersByTab = (tab: number): Filter[] => {
  const filter = {
    colName: 'StatusId',
    operator: FilterOperator.IN,
  };
  switch (tab) {
    case 0:
      return [
        {
          ...filter,
          colValue: [AlertStatusType.Open, AlertStatusType.Accepted, AlertStatusType.Resolving],
        },
      ];
    case 1:
      return [
        {
          ...filter,
          colValue: [
            AlertStatusType.Resolved,
            AlertStatusType.ManuallyClosed,
            AlertStatusType.Deactivated,
          ],
        },
      ];
    default:
      return [];
  }
};

export const getAlertsRequest = (deviceAccessGroupIds: string[] = []): AlertsRequest => {
  const alertsRequest: AlertsRequest = {
    filters: [
      {
        colName: 'StatusId',
        operator: FilterOperator.IN,
        colValue: [AlertStatusType.Open, AlertStatusType.Accepted, AlertStatusType.Resolving],
      },
    ],
    searchValue: '',
    sortColumnName: 'Severity',
    sortOrder: 'desc',
    pageSize: 1000,
  };

  if (deviceAccessGroupIds?.length) {
    alertsRequest.filters!.push({
      colName: 'deviceAccessGroupId',
      operator: FilterOperator.IN,
      colValue: deviceAccessGroupIds,
    });
  }
  return alertsRequest;
};

<dpdhl-modal
  *ngIf="open && alert && action"
  (modalClose)="closeDialog.emit()"
  modalTitle="{{ 'dashboard.alert.context.' + action.toLowerCase() | translate | titlecase }}"
  data-test-id="workflowDialog"
>
  <ng-container *ngIf="alert">
    <dpdhl-card>
      <div class="p-16 space-y-16 flex flex-col">
        <div class="flex py-16">
          <div class="flex flex-grow space-x-8">
            <span>{{ 'dashboard.alert.deviceId' | translate }} :</span>
            <strong data-test-id="deviceIdName">{{
              alert?.deviceId + ' ' + alert?.deviceName
            }}</strong>
            <span>{{ 'dashboard.alert.deviceTimestamp' | translate }} :</span>
            <strong data-test-id="deviceTimestamp">{{
              alert?.deviceTimestamp | date: 'dd.MM.yyyy HH:mm'
            }}</strong>
          </div>
          <div class="flex space-x-4 items-center">
            <div
              class="dpdhl-rounded-8 text-center px-8 py-8 text-body2 {{
                getAlertStatusChipColor(alert?.statusId)
              }}"
            ></div>
            <span data-test-id="alertCurrentStatus">{{
              'dashboard.alert.' + alert?.statusId?.toString().toLowerCase() | translate
            }}</span>
          </div>
        </div>
        <div *ngIf="enableCommenting" class="w-full py-8 space-y-8">
          <p>{{ 'dashboard.alert.comments' | translate }}</p>
          <dpdhl-textarea
            [_formGroup]="workflowFormGroup"
            [_formControlName]="'commentBox'"
            label="{{ 'dashboard.alert.enterComments' | translate }}"
            data-test-id="alertCommentInput"
            [rows]="4"
            [required]="isCommentRequired"
          ></dpdhl-textarea>
        </div>
        <div class="flex dpdhl-gap-x-16">
          <div *ngIf="isTncRequired">
            <dpdhl-checkbox
              class="place-self-center"
              [_formGroup]="workflowFormGroup"
              label="{{ 'dashboard.alert.context.declaration' + action | translate }}"
              _formControlName="tncCheckbox"
              data-test-id="alertTncCheckbox"
            ></dpdhl-checkbox>
          </div>
          <div *ngIf="noFindingsApplicable">
            <dpdhl-checkbox
              class="place-self-center"
              [_formGroup]="workflowFormGroup"
              label="{{ 'predictiveMaintenance.common.noFindings' | translate }}"
              _formControlName="noFindingsCheckbox"
              (change)="noFindingsChecked($event?.target?.checked)"
              data-test-id="alertNoFindingsCheckbox"
            ></dpdhl-checkbox>
          </div>
        </div>

        <div *ngIf="alert?.alertRemarks.length > 0" class="" data-test-id="alertRemarks">
          <dpdhl-button
            *ngIf="alert?.alertRemarks.length > 2"
            class="static -mt-4"
            form="ghost"
            (buttonClick)="toggleCollapse()"
            data-test-id="btn-collapse"
          >
            <svg-icon
              *ngIf="!isCollapsed"
              title="Close"
              src="/assets/icons/minus.svg"
              class="w-auto h-16 text-default"
            ></svg-icon>
            <svg-icon
              *ngIf="isCollapsed"
              title="Open"
              src="/assets/icons/plus.svg"
              class="w-16 h-16 text-default"
            ></svg-icon>
          </dpdhl-button>
          <ng-container
            *ngFor="let remark of alertRemarks; let i = index; let first = first; let last = last"
          >
            <div
              class="flex ml-48 mb-20"
              data-test-id="div-remarks"
              [ngClass]="{
                'border-l': !last,
                'pb-16': !last,
                '-mt-20': alert?.alertRemarks.length > 2,
              }"
            >
              <div class="w-1/12 flex space-x-8">
                <div class="ml-[-0.5rem]">
                  <div
                    class="text-center z-10 px-8 dpdhl-py-8 dpdhl-rounded-8 text-body2 {{
                      getAlertStatusChipColor(remark.statusId)
                    }}"
                  ></div>
                </div>
                <div class="-mt-4">
                  {{ 'dashboard.alert.' + remark.statusId?.toString().toLowerCase() | translate }}
                  <div
                    *ngIf="remark.additionalData?.NoFindings === 'true'"
                    data-test-id="txtNoFindings"
                    class="text-footnote"
                  >
                    {{ 'predictiveMaintenance.common.noFindings' | translate }}
                  </div>
                </div>
              </div>
              <div class="flex-grow space-y-8">
                <div class="flex items-center space-x-24">
                  <div>
                    {{ remark.timestamp | date: 'dd.MM.yyyy HH:mm' }}
                  </div>
                  <div>
                    <ng-container *ngIf="remark.userName">
                      <img
                        src="assets/images/user.png"
                        alt="User"
                        style="width: 18px; height: 18px"
                      />
                      <span> {{ remark.userName }}</span>
                    </ng-container>
                  </div>
                </div>
                <div class="bg-gray-100 rounded-4 h-64 p-4">
                  <pre>{{ remark.remarks }}</pre>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="flex space-x-16 justify-end" *ngIf="action !== 'View'">
          <dpdhl-button
            (buttonClick)="closeDialog.emit()"
            [form]="'button'"
            [type]="'button'"
            [variant]="'secondary'"
            >{{ 'common.cancel' | translate }}
          </dpdhl-button>
          <dpdhl-button
            (buttonClick)="saveWorkflow()"
            [disabled]="workflowFormGroup.invalid"
            [form]="'button'"
            [type]="'submit'"
            [variant]="'primary'"
            data-test-id="saveWorkflowButton"
            >{{ 'common.submit' | translate }}
          </dpdhl-button>
        </div>
      </div>
    </dpdhl-card>
  </ng-container>
</dpdhl-modal>

<dpdhl-modal
  *ngIf="open && !alert && !action"
  (modalClose)="closeDialog.emit()"
  modalTitle=""
  data-test-id="workflowLoadingDialog"
>
  <ng-container>
    <div>
      <dpdhl-skeleton-card [withTitle]="true" [classes]="['w-full']"></dpdhl-skeleton-card>
    </div>
  </ng-container>
</dpdhl-modal>

/**
 * IOT.Authorization.API.WebApp
 * API for managing authorization on the platform
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type HierarchyNodeType = 'None' | 'DHL' | 'BusinessUnit' | 'Application' | 'CustomEntity' | 'UserGroup' | 'DeviceAccessGroup' | 'AccessGroup';

export const HierarchyNodeType = {
    NONE: 'None' as HierarchyNodeType,
    DHL: 'DHL' as HierarchyNodeType,
    BUSINESS_UNIT: 'BusinessUnit' as HierarchyNodeType,
    APPLICATION: 'Application' as HierarchyNodeType,
    CUSTOM_ENTITY: 'CustomEntity' as HierarchyNodeType,
    USER_GROUP: 'UserGroup' as HierarchyNodeType,
    DEVICE_ACCESS_GROUP: 'DeviceAccessGroup' as HierarchyNodeType,
    ACCESS_GROUP: 'AccessGroup' as HierarchyNodeType
};

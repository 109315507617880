import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { DPDHLTable } from '../../como-ui.types';

@Component({
  selector: 'dpdhl-table-column',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent {
  @Input() field!: string;
  @Input() header!: string;
  @Input() width?: string;
  @Input() cellType: 'text' | 'custom' = DPDHLTable.CellType.TEXT;
  @Input() customTemplate?: TemplateRef<any>;
  @Input() sortable = true;
  @Input() sortConfig?: DPDHLTable.ColumnSortingConfig;
  @Input() showLoadingSkeleton = true;
  @Input() headerTemplate?: TemplateRef<any>;
}

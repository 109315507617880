/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FilterOperator = 'Equal' | 'NotEqual' | 'Contains' | 'StartsWith' | 'EndsWith' | 'LessThan' | 'GreaterThan' | 'LessEqual' | 'GreaterEqual' | 'In';

export const FilterOperator = {
    EQUAL: 'Equal' as FilterOperator,
    NOT_EQUAL: 'NotEqual' as FilterOperator,
    CONTAINS: 'Contains' as FilterOperator,
    STARTS_WITH: 'StartsWith' as FilterOperator,
    ENDS_WITH: 'EndsWith' as FilterOperator,
    LESS_THAN: 'LessThan' as FilterOperator,
    GREATER_THAN: 'GreaterThan' as FilterOperator,
    LESS_EQUAL: 'LessEqual' as FilterOperator,
    GREATER_EQUAL: 'GreaterEqual' as FilterOperator,
    IN: 'In' as FilterOperator
};


import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationMenu } from '@dpdhl/iot-shared-ui';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
})
export class ManagementComponent implements OnInit, OnDestroy {
  applicationTitle: string[] = ['Condition', 'Monitoring'];
  navigationMenu: NavigationMenu[] = [];

  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.routeToDevice();
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.routeToDevice();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private routeToDevice(): void {
    const manageRoute = sessionStorage.getItem('manageRoute');
    const urlFragments = this.router.url.split('/');
    const applicationId = urlFragments.includes('applications');
    const areaId = urlFragments.includes('areas');

    if (manageRoute && (!applicationId || !areaId)) {
      this.router.navigate([manageRoute]);
    } else {
      const deviceRoute = sessionStorage.getItem('deviceRoute');

      if (deviceRoute && (!applicationId || !areaId)) {
        this.router.navigate([deviceRoute]);
      }
    }
  }
}

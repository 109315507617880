/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AlertStatus = 'All' | 'Open' | 'Accepted' | 'Resolving' | 'Resolved' | 'Deactivated' | 'ManuallyClosed';

export const AlertStatus = {
    ALL: 'All' as AlertStatus,
    OPEN: 'Open' as AlertStatus,
    ACCEPTED: 'Accepted' as AlertStatus,
    RESOLVING: 'Resolving' as AlertStatus,
    RESOLVED: 'Resolved' as AlertStatus,
    DEACTIVATED: 'Deactivated' as AlertStatus,
    MANUALLY_CLOSED: 'ManuallyClosed' as AlertStatus
};


<ng-container *ngIf="legends && legends.length > 0">
  <div class="flex flex-wrap gap-x-32 my-16">
    <div class="flex items-left flex-wrap gap-x-16 mb-12">
      <dpdhl-switch
        label="{{ 'alert.containerName' | translate }}"
        [_formGroup]="formGroup"
        _formControlName="alertsOnChart"
        title="{{ 'room.chart.showHideAlertsOnChart' | translate }}"
        data-test-id="switchAlertsOnChart"
      ></dpdhl-switch>
      <div class="flex flex-wrap items-center gap-x-4">
        (
        <div class="flex items-end">
          <svg class="h-16 w-16">
            <polygon points="6,2 12,12 0,12" fill="rgba(235, 19, 30, 0.5)" />
          </svg>
          <div class="text-caption1">{{ 'dashboard.alert.open' | translate }}</div>
        </div>
        <div class="flex items-end ml-8">
          <svg class="h-16 w-16">
            <polygon points="0,2 12,2 6,12" fill="rgba(140, 140, 140, 0.5)" />
          </svg>
          <div class="text-caption1">
            {{ 'dashboard.alert.resolved' | translate }},
            {{ 'dashboard.alert.manuallyclosed' | translate }},
            {{ 'dashboard.alert.deactivated' | translate }}
          </div>
        </div>
        )
      </div>
    </div>
    <div *ngIf="showExternalWeather">
      <dpdhl-switch
        label="{{ 'room.chart.externalWeather' | translate }}"
        [_formGroup]="formGroup"
        _formControlName="externalWeather"
        title="{{ 'room.chart.externalWeather' | translate }}"
        data-testid="switchExternalWeather"
      ></dpdhl-switch>
    </div>
  </div>

  <div *ngIf="!filter" class="flex items-left flex-wrap py-8 gap-x-16">
    <dpdhl-checkbox
      label="{{ 'room.chart.selectAll' | translate }}"
      [_formGroup]="formGroup"
      _formControlName="allDeviceCheckbox"
      title="{{ getAllDeviceCheckboxHint() | translate }}"
      data-testid="checkAllDevices"
    ></dpdhl-checkbox>
  </div>
  <div class="grid grid-cols-6 gap-x-12 mt-12">
    <div
      *ngFor="let legend of data"
      [ngClass]="{ 'text-tertiary': !legend.selected, 'cursor-pointer': !filter }"
      (click)="!filter && toggle(legend)"
      class="flex items-baseline gap-x-4"
      data-testid="div-legend-container"
    >
      <app-graph-legend-shape [legend]="legend"></app-graph-legend-shape>
      <div class="text-caption1">
        {{ legend.name | translate }}
      </div>
    </div>
  </div>
</ng-container>

/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationDeviceFilter = 'All' | 'Active' | 'Inactive';

export const ApplicationDeviceFilter = {
    ALL: 'All' as ApplicationDeviceFilter,
    ACTIVE: 'Active' as ApplicationDeviceFilter,
    INACTIVE: 'Inactive' as ApplicationDeviceFilter
};


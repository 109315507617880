<ng-container *transloco="let t; read: 'table'">
  <div *ngIf="withActionRow" class="dpdhl-border-t-4 dpdhl-border-gray-300 dpdhl-pt-16">
    <dpdhl-table-action-row
      [tableData]="filteredData.elements"
      [downloadConfig]="downloadConfig"
      [actionRowConfig]="actionRowConfig"
      (searchListByValue)="onSearchListByValue($event)"
    ></dpdhl-table-action-row>
  </div>
  <div
    class="dpdhl-grid dpdhl-gap-y-12 dpdhl-gap-x-16"
    [ngStyle]="gridColsStyle"
    [ngClass]="{ 'dpdhl-border-t': withActionRow, 'dpdhl-border-t-4': !withActionRow }"
  >
    <div *ngIf="!!detailRowConfig.template" class="w-4" data-testid="table-expander-header"></div>
    <ng-container *ngFor="let column of columns">
      <div class="dpdhl-font-bold dpdhl-py-16 dpdhl-flex">
        <ng-container *ngIf="column.headerTemplate">
          <ng-template *ngTemplateOutlet="column.headerTemplate"></ng-template>
        </ng-container>
        <div *ngIf="!column.headerTemplate">{{ column.header }}</div>
        <ng-container *ngIf="sorting && column.sortable">
          <div
            class="dpdhl-ml-8 dpdhl-cursor-pointer"
            [attr.data-testid]="'column-sort-' + column.field"
            (click)="onSort(column)"
          >
            <dpdhl-svg-icon [src]="getSortIconSrc(column)"></dpdhl-svg-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="dpdhl-border-b-4 dpdhl-border-gray-300">
    <ng-container *ngIf="dataAvailable">
      <ng-container *ngIf="(dataChunk$ | async)?.elements?.length">
        <ng-container *ngFor="let row of (dataChunk$ | async)?.elements; even as even">
          <dpdhl-table-row
            class="dpdhl-grid dpdhl-items-center dpdhl-gap-y-12 dpdhl-gap-x-16 dpdhl-border-t dpdhl-border-gray-100"
            [ngClass]="{
              'dpdhl-bg-gray-50': even
            }"
            [ngStyle]="gridColsStyle"
            [columns]="columns"
            [row]="row"
            [even]="even"
            [detailRowField]="detailRowConfig.field"
          ></dpdhl-table-row>
          <ng-container *ngIf="detailRowConfig.template && row.expanded">
            <ng-template
              *ngTemplateOutlet="detailRowConfig.template; context: { row: row }"
            ></ng-template>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="dpdhl-py-24" *ngIf="withPagination">
        <dpdhl-pagination
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [totalElementCount]="filteredData.elements.length"
          [initialPage]="initialPage"
        ></dpdhl-pagination>
      </div>
    </ng-container>
    <div
      class="dpdhl-grid dpdhl-items-center dpdhl-gap-y-12 dpdhl-gap-x-16 dpdhl-border-t dpdhl-border-gray-100 dpdhl-bg-gray-50"
      [ngStyle]="gridColsStyle"
      *ngIf="loading"
    >
      <div
        class="dpdhl-py-16 dpdhl-grid dpdhl-animate-pulse"
        *ngFor="let column of skeletonColumns"
      >
        <dpdhl-skeleton-text *ngIf="column.show" class="dpdhl-w-2/3"></dpdhl-skeleton-text>
      </div>
    </div>
    <div class="dpdhl-grid" [ngStyle]="gridColsStyle" *ngIf="dataEmpty">
      <div
        data-testid="table-empty-result"
        class="dpdhl-px-8 dpdhl-py-16 dpdhl-bg-gray-50 dpdhl-col-span-full dpdhl-text-center dpdhl-border-t dpdhl-border-gray-100 dpdhl-text-secondary"
      >
        {{ t('messageNoData') }}
      </div>
    </div>
  </div>
</ng-container>

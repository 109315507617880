<ng-container [ngSwitch]="legend.legendShape">
  <svg height="12" width="12" *ngSwitchCase="'circle'">
    <ellipse rx="6" ry="6" cx="6" cy="6" [ngStyle]="{ fill: legend.color }"></ellipse>
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'rect'">
    <polygon points="0, 0, 12, 0, 12, 12, 0, 12" [ngStyle]="{ fill: legend.color }" />
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'triangle'">
    <polygon points="0, 12, 6, 0, 12, 12" [ngStyle]="{ fill: legend.color }" />
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'star'">
    <line x1="6" y1="0" x2="6" y2="12" stroke-width="2" [ngStyle]="{ stroke: legend.color }"></line>
    <line x1="0" y1="6" x2="12" y2="6" stroke-width="2" [ngStyle]="{ stroke: legend.color }"></line>
    <line
      x1="2"
      y1="10"
      x2="10"
      y2="2"
      stroke-width="2"
      [ngStyle]="{ stroke: legend.color }"
    ></line>
    <line
      x1="2"
      y1="2"
      x2="10"
      y2="10"
      stroke-width="2"
      [ngStyle]="{ stroke: legend.color }"
    ></line>
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'cross'">
    <line x1="6" y1="0" x2="6" y2="12" stroke-width="2" [ngStyle]="{ stroke: legend.color }" />
    <line x1="0" y1="6" x2="12" y2="6" stroke-width="2" [ngStyle]="{ stroke: legend.color }" />
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'rectRounded'">
    <rect [ngStyle]="{ fill: legend.color }" width="12" height="12" rx="4" />
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'crossRot'">
    <line x1="0" y1="12" x2="12" y2="0" stroke-width="2" [ngStyle]="{ stroke: legend.color }" />
    <line x1="0" y1="0" x2="12" y2="12" stroke-width="2" [ngStyle]="{ stroke: legend.color }" />
  </svg>
  <svg height="12" width="12" *ngSwitchCase="'rectRot'">
    <polygon points="0 6,6 12,12 6,6 0" [ngStyle]="{ fill: legend.color }" />
  </svg>
</ng-container>

import { Injectable } from '@angular/core';
import { CoreConstants } from '@dpdhl-iot/shared';
import { DisplayUserViewModel } from './models/display-user-view-model';
import { from, Observable, map } from 'rxjs';
import { DisplayUserService } from '@dpdhl-iot/api/management';

@Injectable({
  providedIn: 'root',
})
export class DisplayUsersService {
  constructor(private readonly displayUserService: DisplayUserService) {}

  getDisplayUsersByIds(ids: string[]): Observable<DisplayUserViewModel[]> {
    return from(this.displayUserService.getDisplayUsersByIds(CoreConstants.API_VERSION, ids)).pipe(
      map((result) => result.map((user) => new DisplayUserViewModel(user))),
    );
  }
}

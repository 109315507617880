export * from './alert.service';
import { AlertService } from './alert.service';
export * from './application.service';
import { ApplicationService } from './application.service';
export * from './application-telemetry.service';
import { ApplicationTelemetryService } from './application-telemetry.service';
export * from './co-mo-alert-rule.service';
import { CoMoAlertRuleService } from './co-mo-alert-rule.service';
export * from './co-mo-alert-rule-template.service';
import { CoMoAlertRuleTemplateService } from './co-mo-alert-rule-template.service';
export * from './device-management.service';
import { DeviceManagementService } from './device-management.service';
export * from './inventory-tracking.service';
import { InventoryTrackingService } from './inventory-tracking.service';
export * from './mobile-monitoring.service';
import { MobileMonitoringService } from './mobile-monitoring.service';
export * from './notification-config.service';
import { NotificationConfigService } from './notification-config.service';
export * from './report.service';
import { ReportService } from './report.service';
export const APIS = [AlertService, ApplicationService, ApplicationTelemetryService, CoMoAlertRuleService, CoMoAlertRuleTemplateService, DeviceManagementService, InventoryTrackingService, MobileMonitoringService, NotificationConfigService, ReportService];

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DPDHLTable } from '../../como-ui.types';

@Component({
  selector: 'dpdhl-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowComponent {
  @Input() row!: DPDHLTable.TableRow;
  @Input() columns!: any;
  @Input() even = false;
  @Input() detailRowField = '';
  getCell(row: DPDHLTable.TableRow, field: string) {
    return row[field] || '';
  }
  get tableColumns() {
    return this.detailRowField
      ? [
          { cellType: DPDHLTable.CellType.EXPANDER.toString(), field: this.detailRowField },
          ...this.columns,
        ]
      : this.columns;
  }
  onRowExpand(row: DPDHLTable.TableRow) {
    row.expanded = !row.expanded;
  }
  showExpander(row: DPDHLTable.TableRow, field: string) {
    const data = row[field];
    return Array.isArray(data) ? data.length > 0 : !!data;
  }
}

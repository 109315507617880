import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegendModel } from '@dpdhl-iot/shared';

@Component({
  selector: 'app-graph-legend-shape',
  templateUrl: './graph-legend-shape.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphLegendShapeComponent {
  @Input() legend: LegendModel;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import {
  ApplicationService,
  CoMoAlertRuleTemplate,
  CoMoAlertRuleTemplateGroup,
  FacilityInfo,
  FileDownloadResponse,
  FileType,
  InventoryDeviceDetails,
  InventoryTelemetryDownloadRequest,
  InventoryTrackingService,
  LrtReportRequest,
  Org,
  SubOrg,
} from '@dpdhl-iot/api/backend';
import {
  AlertRuleTemplateManagementService,
  AreaReportTypes,
  CoreConstants,
  entitiesEqual,
  FacilityReportTypes,
  ReportDownloadOptions,
} from '@dpdhl-iot/shared';
import { ApplicationDataService, EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { IotApplicationModel } from '@dpdhl-iot/api/management';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InventoryTrackingFacilityService {
  private selectedApplication: IotApplicationModel;

  private readonly filteredFacilitiesProvider = new BehaviorSubject<FacilityInfo[]>([]);
  private readonly selectedFacilityProvider = new Subject<Org>();
  private readonly selectedAreaProvider = new Subject<SubOrg>();
  private applicationFacilities: FacilityInfo[] = [];
  private prevFilteredFacilities: FacilityInfo[] = [];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedFacility$ = this.selectedFacilityProvider.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedArea$ = this.selectedAreaProvider.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public filteredFacilities$ = this.filteredFacilitiesProvider.asObservable();

  constructor(
    private readonly applicationDataService: ApplicationDataService,
    private readonly applicationService: ApplicationService,
    private readonly inventoryApplicationService: InventoryTrackingService,
    private readonly alertRuleTemplateService: AlertRuleTemplateManagementService,
  ) {
    this.applicationDataService.application$
      .pipe(
        filter((a) => !!a.application),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((app) => {
        this.applicationFacilities = [];
        this.setFilteredFacilities(this.applicationFacilities);
        this.selectedApplication = app;
      });
  }

  getInventoryDeviceDetailsAsync(
    zoneIds: string[],
    inTransitCutoffThreshold?: number,
  ): Observable<InventoryDeviceDetails[]> {
    return this.applicationService.getInventoryDeviceDetailsAsync(
      this.selectedApplication.uuid,
      this.selectedApplication.id,
      CoreConstants.API_VERSION,
      inTransitCutoffThreshold,
      zoneIds,
    );
  }

  downloadInventoryDeviceTelemetryAsync(
    fileType: FileType,
    request: InventoryTelemetryDownloadRequest,
  ): Observable<FileDownloadResponse> {
    return this.applicationService.downloadInventoryDeviceTelemetryAsync(
      this.selectedApplication.uuid,
      this.selectedApplication.id,
      fileType,
      CoreConstants.API_VERSION,
      request,
    );
  }

  setFilteredFacilities(filteredFacilities: FacilityInfo[]) {
    if (!entitiesEqual(filteredFacilities, this.prevFilteredFacilities)) {
      this.filteredFacilitiesProvider.next(filteredFacilities);
      this.prevFilteredFacilities = filteredFacilities;
    }
  }

  setSelectedFacility(facilityId: string) {
    this.getApplicationFacilityDetails(facilityId).subscribe((facility) => {
      if (facility) {
        this.selectedFacilityProvider.next(facility);
      }
    });
  }

  setSelectedArea(area: SubOrg): void {
    this.selectedAreaProvider.next(area);
  }

  getApplicationFacilityDetails(facilityId: string): Observable<Org> {
    return this.inventoryApplicationService.getFacilityDetails(
      this.selectedApplication.uuid,
      this.selectedApplication.id,
      facilityId,
      CoreConstants.API_VERSION,
    );
  }

  getInventoryTrackingDashboard(): Observable<FacilityInfo[]> {
    if (this.applicationFacilities.length === 0) {
      return this.inventoryApplicationService
        .getDashboardFacilities(
          this.selectedApplication.id,
          this.selectedApplication.uuid,
          CoreConstants.API_VERSION,
        )
        .pipe(
          map((facilities) => {
            facilities.sort((a, b) => a.name!.localeCompare(b.name!));
            this.applicationFacilities = facilities;
            this.setFilteredFacilities(facilities);
            return facilities;
          }),
        );
    }
    return of(this.applicationFacilities);
  }

  getTemplateGroups(): Observable<CoMoAlertRuleTemplateGroup[]> {
    return this.alertRuleTemplateService.getAlertRuleTemplateGroups(this.selectedApplication.uuid);
  }

  getTemplatesByAreaId(areaId: string): Observable<CoMoAlertRuleTemplate[]> {
    return this.alertRuleTemplateService.getByAreaId(
      this.selectedApplication.uuid,
      areaId,
      EnvironmentalUnitSystem.METRIC,
    );
  }

  removeTemplateByAreaId(areaId: string, templateId: string): Observable<boolean> {
    return this.alertRuleTemplateService.removeByAreaId(
      this.selectedApplication.uuid,
      areaId,
      templateId,
    );
  }

  getTemplatesByFacilityId(facilityId: string): Observable<CoMoAlertRuleTemplate[]> {
    return this.alertRuleTemplateService.getByFacilityId(
      this.selectedApplication.uuid,
      facilityId,
      EnvironmentalUnitSystem.METRIC,
    );
  }

  downloadLrtReport(
    zones: string[],
    dateRange: Date[],
    options: ReportDownloadOptions,
  ): Observable<FileDownloadResponse> {
    const request: LrtReportRequest = {
      from: dateRange[0].getTime(),
      until: dateRange[1].getTime(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      zones,
      aggregated:
        options.type === FacilityReportTypes.LrtAggregated ||
        options.type === AreaReportTypes.LrtAggregated,
      applicationId: this.selectedApplication.id,
      applicationGuId: this.selectedApplication.uuid,
    };

    return this.inventoryApplicationService.downloadLrtReport(
      options.extension,
      CoreConstants.API_VERSION,
      request,
    );
  }
}

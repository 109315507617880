export enum SensorType {
  CO2 = 'CO2',
  ParticulateMatter10 = 'ParticulateMatter10',
  ParticulateMatter2_5 = 'ParticulateMatter2_5',
  ParticulateMatter1_0 = 'ParticulateMatter1_0',
  Temperature = 'Temperature',
  Humidity = 'Humidity',
  PalletWrapped = 'PalletWrapped',
  SoC = 'SoC',
  SoH = 'SoH',
  Battery = 'Battery',
  Current = 'Current',
  PalletAggregation = 'PalletAggregation',
  ParticulateMatter = 'ParticulateMatter',
  DevicesCount = 'DevicesCount',
}

export enum AlertActionType {
  View = 'View',
  Comment = 'Comment',
  Accept = 'Accept',
  Resolving = 'Resolving',
  Close = 'Close',
}

export enum AlertStatusType {
  All = 'All',
  Open = 'Open',
  Accepted = 'Accepted',
  Resolving = 'Resolving',
  Resolved = 'Resolved',
  Deactivated = 'Deactivated',
  ManuallyClosed = 'ManuallyClosed',
}

export enum AlertType {
  Temperature = 'Temperature',
  Humid = 'Humid',
  Humidity = 'Humidity',
  Battery = 'Battery',
}

export enum AlertApplicationType {
  ConditionMonitoring = 'ConditionMonitoring',
  InventoryTracking = 'InventoryTracking',
}

export enum AlertPageType {
  dashboard = 'dashboard',
  facility = 'facility',
  area = 'area',
  standAlone = 'standAlone',
}

import { Injectable } from '@angular/core';
import {
  AlertMapperService,
  AlertViewModel,
  BaseAlertDataService,
  AlertManagementService,
  ViewConfigurationService,
  AlertRemarkViewModel,
  UserPreferenceDataService,
} from '@dpdhl-iot/shared';
import { forkJoin, map, Observable, tap } from 'rxjs';
import { DeviceDetails, Filter, UpdateAlertStatusRequest } from '@dpdhl-iot/api/backend';
import {
  CellAlertSeverity,
  DeviceCountMapping,
  DeviceDetailsPredictiveMaintenance,
  PredictiveMaintenanceService,
} from '@dpdhl-iot/predictive-maintenance';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class PredictiveMaintenanceAlertDataService extends BaseAlertDataService {
  predictiveMaintenanceDevices: DeviceDetailsPredictiveMaintenance[];

  constructor(
    readonly alertManagementService: AlertManagementService,
    readonly alertMapperService: AlertMapperService,
    readonly viewConfigurationService: ViewConfigurationService,
    readonly userPreferenceDataService: UserPreferenceDataService,
    private readonly predictiveMaintenanceService: PredictiveMaintenanceService,
  ) {
    super(
      alertManagementService,
      alertMapperService,
      userPreferenceDataService,
      viewConfigurationService,
    );
    predictiveMaintenanceService.predictiveMaintenanceDevices.subscribe(
      (devices) => (this.predictiveMaintenanceDevices = devices),
    );
  }

  override fetchAlerts(
    removeValuePrefixes = false,
    deviceAccessGroupIds: string[] | null = null,
    filters: Filter[] | null = null,
    areaIds: string[] | null = null,
    includeAlertRemarks: boolean | null = null,
  ): Observable<AlertViewModel[]> {
    return super
      .fetchAlerts(removeValuePrefixes, deviceAccessGroupIds, filters, areaIds, includeAlertRemarks)
      .pipe(
        tap((alerts) => {
          alerts.forEach((alert) => {
            const device = this.predictiveMaintenanceDevices.find(
              (a) => a.deviceId === alert.deviceId,
            );
            if (device?.deviceName) {
              alert.deviceName = device.deviceName;
            }
          });
        }),
      );
  }

  override updateAlertStatus(
    alert: AlertViewModel,
    req: UpdateAlertStatusRequest,
  ): Observable<AlertViewModel> {
    const observables = [super.updateAlertStatus(alert, req)];

    if (alert.alertType === 'CellAlert') {
      observables.push(
        this.predictiveMaintenanceService.postponeAlert(
          alert.deviceId,
          parseInt(alert.threshold, 10),
        ),
      );
    }

    return forkJoin(observables).pipe(map((r) => r[0]));
  }

  loadAlertHistory(
    selectedCell: number,
    deviceId: string,
    dateRangeStart: number,
    dateRangeEnd: number,
  ): Observable<{ [key: number]: AlertRemarkViewModel[] } | undefined> {
    return this.fetchAlerts(
      true,
      null,
      [
        {
          colName: 'threshold',
          colValue: selectedCell,
          operator: 'Equal',
        },
        {
          colName: 'deviceId',
          colValue: deviceId,
          operator: 'Equal',
        },
      ],
      null,
      true,
    ).pipe(
      map((alerts) => {
        if (alerts.length === 0 || !alerts[0].alertRemarks) {
          return;
        }
        // Get AlertRemarks for the displayed Date range
        const alertRemarks = alerts[0].alertRemarks.filter(
          (a) => a.timestamp >= dateRangeStart && a.timestamp <= dateRangeEnd,
        );

        // Group AlertRemarks by Day
        return alertRemarks.reduce((group: { [key: number]: AlertRemarkViewModel[] }, item) => {
          const day = DateTime.fromMillis(item.timestamp).startOf('day').toMillis();
          if (!group[day]) {
            group[day] = [];
          }
          group[day].push(item);
          return group;
        }, {});
      }),
    );
  }

  mapAlertsToDeviceCountMapping(devices: DeviceDetails[]): Record<string, DeviceCountMapping> {
    const deviceCountMapping: Record<string, DeviceCountMapping> = {};
    devices.forEach((device) => {
      const sensor = device as DeviceDetailsPredictiveMaintenance;
      deviceCountMapping[sensor.deviceId!] = {
        alertCount: sensor.alertCells.filter((a) => a.alertState === CellAlertSeverity.ALERT)
          .length,
        warningCount: sensor.alertCells.filter((a) => a.alertState === CellAlertSeverity.WARNING)
          .length,
        normalCount: sensor.sensor.beltCells
          ? sensor.sensor.beltCells - sensor.alertCells.length
          : 0,
        isActive: sensor.isActive === true,
        alerts: sensor.alerts,
      };
    });
    return deviceCountMapping;
  }
}

import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { JSON2SheetOpts } from 'xlsx';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportHelperService {
  public exportData(
    sheets: {
      sheetName: string;
      sheetData: any[];
      columnWidthConfig?: XLSX.ColInfo[];
      sheetOptions?: JSON2SheetOpts;
    }[],
    fileName: string,
  ): void {
    const generatedAt = DateTime.now().toJSDate();
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    const defaultOption = { dateNF: 'mm/dd/yyyy hh:mm:ss' };

    sheets.forEach((sheet) => {
      sheet.sheetData.forEach((sheetRow) => {
        sheetRow['Generated At'] = generatedAt;
      });

      sheet.sheetOptions = sheet.sheetOptions
        ? { ...sheet.sheetOptions, ...defaultOption }
        : defaultOption;
      const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        sheet.sheetData,
        sheet.sheetOptions,
      );

      if (sheet.columnWidthConfig && sheet.columnWidthConfig.length > 0) {
        workSheet['!cols'] = sheet.columnWidthConfig;
      }

      XLSX.utils.book_append_sheet(workBook, workSheet, sheet.sheetName);
    });

    workBook.Props = workBook.Props ?? {};

    //Updating meta for security.
    const wb_props = workBook.Props;
    workBook.Props = new Proxy(wb_props, {
      get: (o: any, p: any) => (p == 'Application' ? '' : o[p]),
    });

    XLSX.writeFile(workBook, `${this.exportTimeStamp}_${fileName}.xlsx`);
  }

  private get exportTimeStamp(): string {
    return this.dateToFormat(undefined, 'yyyyLLdd_HHmm');
  }

  private jsDateToDateTime(date?: Date | number | string): DateTime {
    if (date) {
      if (typeof date !== 'object') {
        return DateTime.fromJSDate(new Date(date));
      }
      return DateTime.fromJSDate(date);
    }
    return DateTime.now();
  }

  private dateToFormat(date: Date | number | string | undefined, formatString: string): string {
    return this.jsDateToDateTime(date).toFormat(formatString);
  }
}

import { Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { DisplayUsersService } from '../display-users/display-users.service';
import {
  AlertRuleModel,
  CoMoAlertRuleTemplate,
  CoMoAlertRuleTemplateGroup,
  CoMoAlertRuleTemplateService,
  CompareValueAlertTriggerConditions,
  CreateAlertRulesFromTemplateRequest,
  CreateCoMoAlertRuleTemplateRequest,
  SensorType,
  UpdateCoMoAlertRuleTemplateRequest,
} from '@dpdhl-iot/api/backend';
import { CoreConstants } from '../../constants/core-constants';
import { EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { celsiusToFahrenheit, fahrenheitToCelsius } from '../../global-functions/global.function';

export const COMO_DEFAULT_TEMPLATE_NAME = 'General Use Case templates';
@Injectable({
  providedIn: 'root',
})
export class AlertRuleTemplateManagementService {
  constructor(
    private readonly comoAlertRuleTemplateService: CoMoAlertRuleTemplateService,
    private readonly displayUsersService: DisplayUsersService,
  ) {}

  create(
    create: CreateCoMoAlertRuleTemplateRequest,
    unitSystem: EnvironmentalUnitSystem,
  ): Observable<CoMoAlertRuleTemplate> {
    this.updateTemplateRuleCategory(create);
    this.updateTemplateRuleUnitSystem(create, unitSystem);
    return this.comoAlertRuleTemplateService.createCoMoAlertRuleTemplate(
      CoreConstants.API_VERSION,
      create,
    );
  }

  createAlertRulesFromTemplate(
    request: CreateAlertRulesFromTemplateRequest,
  ): Observable<AlertRuleModel[]> {
    return this.comoAlertRuleTemplateService.createAlertRulesFromTemplate(
      CoreConstants.API_VERSION,
      request,
    );
  }

  deleteById(id: string): Observable<CoMoAlertRuleTemplate> {
    return this.comoAlertRuleTemplateService.deleteById(id, CoreConstants.API_VERSION);
  }

  getAlertRuleTemplateGroups(applicationId: string): Observable<CoMoAlertRuleTemplateGroup[]> {
    return this.comoAlertRuleTemplateService
      .getAlertRuleTemplateGroups(CoreConstants.API_VERSION, applicationId)
      .pipe(
        map((result) => {
          if (result.length > 0) {
            result.forEach(
              (item) => (item.name = item.name?.replace(' - AlertRuleTemplateGroup', '')),
            );
            // tslint:disable-next-line:no-non-null-assertion
            const first = result.shift()!;
            first.name = COMO_DEFAULT_TEMPLATE_NAME;
            result.sort((a, b) => a.name!.localeCompare(b.name!));
            return [first, ...result];
          }
          return result;
        }),
      );
  }

  getAll(
    applicationId: string,
    unitSystem: EnvironmentalUnitSystem,
  ): Observable<CoMoAlertRuleTemplate[]> {
    return this.comoAlertRuleTemplateService
      .getAllCoMoAlertRuleTemplates(CoreConstants.API_VERSION, applicationId)
      .pipe(
        mergeMap((result) => this.getAlertRuleTemplatesWithUserName(result)),
        map((result) => this.mapUnitSystem(result, unitSystem)),
      );
  }

  getById(id: string): Observable<CoMoAlertRuleTemplate> {
    return this.comoAlertRuleTemplateService.getCoMoAlertRuleTemplate(
      id,
      CoreConstants.API_VERSION,
    );
  }

  update(
    id: string,
    update: UpdateCoMoAlertRuleTemplateRequest,
    unitSystem: EnvironmentalUnitSystem,
  ): Observable<CoMoAlertRuleTemplate> {
    this.updateTemplateRuleCategory(update);
    this.updateTemplateRuleUnitSystem(update, unitSystem);
    return this.comoAlertRuleTemplateService.updateCoMoAlertRuleTemplate(
      id,
      CoreConstants.API_VERSION,
      update,
    );
  }

  deleteByDeviceId(applicationId: string, deviceId: string, templateId: string) {
    return this.comoAlertRuleTemplateService.deleteByDeviceId(
      applicationId,
      deviceId,
      templateId,
      CoreConstants.API_VERSION,
    );
  }

  getAlertRuleTemplatesWithUserName(
    alertRuleTemplates: CoMoAlertRuleTemplate[],
  ): Observable<CoMoAlertRuleTemplate[]> {
    const uniqueUserIDs = alertRuleTemplates.map((item) => item.auditInformation!.createdBy);
    return this.displayUsersService.getDisplayUsersByIds(uniqueUserIDs).pipe(
      map((users) => {
        alertRuleTemplates.forEach((item) => {
          const user = users.find((u) => u.id === item.auditInformation!.createdBy);
          item.auditInformation!.createdBy = user ? user.fullName : '';
        });
        return alertRuleTemplates;
      }),
    );
  }

  getByAreaId(
    applicationGuid: string,
    areaId: string,
    unitSystem: EnvironmentalUnitSystem,
  ): Observable<CoMoAlertRuleTemplate[]> {
    return this.comoAlertRuleTemplateService
      .getByAreaId(applicationGuid, areaId, CoreConstants.API_VERSION)
      .pipe(
        mergeMap((result) => this.getAlertRuleTemplatesWithUserName(result)),
        map((result) => this.mapUnitSystem(result, unitSystem)),
      );
  }

  removeByAreaId(applicationGuid: string, areaId: string, templateId: string): Observable<boolean> {
    return this.comoAlertRuleTemplateService.deleteByAreaId(
      applicationGuid,
      areaId,
      templateId,
      CoreConstants.API_VERSION,
    );
  }

  getByFacilityId(
    applicationGuid: string,
    facilityId: string,
    unitSystem: EnvironmentalUnitSystem,
  ): Observable<CoMoAlertRuleTemplate[]> {
    return this.comoAlertRuleTemplateService
      .getByFacilityId(applicationGuid, facilityId, CoreConstants.API_VERSION)
      .pipe(
        mergeMap((result) => this.getAlertRuleTemplatesWithUserName(result)),
        map((result) => this.mapUnitSystem(result, unitSystem)),
      );
  }

  mapUnitSystem(templates: CoMoAlertRuleTemplate[], unitSystem: EnvironmentalUnitSystem) {
    if (unitSystem === EnvironmentalUnitSystem.IMPERIAL) {
      templates.forEach((template) => {
        template.rules?.forEach((rule) => {
          if (rule.category?.includes(SensorType.TEMPERATURE)) {
            (rule.triggerConditions as CompareValueAlertTriggerConditions).threshold =
              celsiusToFahrenheit(
                (rule.triggerConditions as CompareValueAlertTriggerConditions).threshold,
              );
          }
        });
      });
    }
    return templates;
  }

  private updateTemplateRuleCategory(alertTemplate: UpdateCoMoAlertRuleTemplateRequest) {
    alertTemplate.rules?.forEach((rule) => {
      const compareValueTrigger = rule.triggerConditions as CompareValueAlertTriggerConditions;
      if (!compareValueTrigger.propertyPath) {
        return;
      }

      let category = '';
      switch (compareValueTrigger.propertyPath) {
        case 'SensorMeasurements.Temperature':
          category = 'Temperature';
          break;
        case 'SensorMeasurements.Humidity':
          category = 'Humidity';
          break;
        case 'SensorMeasurements.BatteryPercentage':
          category = 'Battery';
          break;
        case 'SensorMeasurements.CarbonDioxideLevel':
          category = 'CO2';
          break;
        case 'Properties.generalInformation.tot_completed_packing_cycles':
          category = 'Pallet';
          break;
        case 'Properties.generalInformation.soC':
          category = 'SoC';
          break;
        case 'Properties.generalInformation.soH':
          category = 'SoH';
          break;
        case 'Properties.particulateMatterConcentration10':
          category = 'PM10';
          break;
        case 'Properties.["particulateMatterConcentration2.5"]':
          category = 'PM2.5';
          break;
        case 'Properties.["particulateMatterConcentration1.0"]':
          category = 'PM1.0';
          break;
      }
      rule.category = category;
    });
  }

  private updateTemplateRuleUnitSystem(
    alertTemplate: UpdateCoMoAlertRuleTemplateRequest,
    unitSystem: EnvironmentalUnitSystem,
  ) {
    if (unitSystem === EnvironmentalUnitSystem.IMPERIAL) {
      alertTemplate.rules?.forEach((rule) => {
        if (rule.category?.includes(SensorType.TEMPERATURE)) {
          (rule.triggerConditions as CompareValueAlertTriggerConditions).threshold =
            fahrenheitToCelsius(
              (rule.triggerConditions as CompareValueAlertTriggerConditions).threshold,
            );
        }
      });
    }
  }
}

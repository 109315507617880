import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { BackendConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AlertService } from './api/alert.service';
import { ApplicationService } from './api/application.service';
import { ApplicationTelemetryService } from './api/application-telemetry.service';
import { CoMoAlertRuleService } from './api/co-mo-alert-rule.service';
import { CoMoAlertRuleTemplateService } from './api/co-mo-alert-rule-template.service';
import { DeviceManagementService } from './api/device-management.service';
import { InventoryTrackingService } from './api/inventory-tracking.service';
import { MobileMonitoringService } from './api/mobile-monitoring.service';
import { NotificationConfigService } from './api/notification-config.service';
import { ReportService } from './api/report.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class BackendApiModule {
    public static forRoot(configurationFactory: () => BackendConfiguration): ModuleWithProviders<BackendApiModule> {
        return {
            ngModule: BackendApiModule,
            providers: [ { provide: BackendConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: BackendApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('BackendApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

import { Injectable } from '@angular/core';
import { AlertRemarkViewModel, AlertViewModel } from './alert-view.model';
import { combineLatest, map, Observable } from 'rxjs';
import { AlertMapperService } from './alert-mapper.service';
import {
  AlertsRequest,
  Filter,
  FilterOperator,
  UpdateAlertStatusRequest,
} from '@dpdhl-iot/api/backend';
import { AlertStatusType } from './alert.enum';
import { ViewConfigurationModel } from '../view-configuration/view-configuration.model';
import { ViewConfigurationService } from '../view-configuration/view-configuration.service';
import { AlertManagementService } from '../alert-management/alert-management.service';
import { AlertChartModel } from '../../models/alert-chart.model';
import { CoreConstants } from '../../constants/core-constants';
import { UserPreferenceDataService } from '../user-preferences/user-preference-data.service';

@Injectable({ providedIn: 'root' })
export class BaseAlertDataService {
  viewConfiguration: ViewConfigurationModel;

  constructor(
    protected readonly alertManagementService: AlertManagementService,
    protected readonly alertMapperService: AlertMapperService,
    protected readonly userPreferenceDataService: UserPreferenceDataService,
    protected readonly viewConfigurationService: ViewConfigurationService,
  ) {
    this.viewConfigurationService.currentViewConfiguration$.subscribe(
      (viewConfiguration) => (this.viewConfiguration = viewConfiguration),
    );
  }

  fetchAlerts(
    removeValuePrefixes = false,
    deviceAccessGroupIds: string[] | null = null,
    filters: Filter[] | null = null,
    areaIds: string[] | null = null,
    includeAlertRemarks: boolean | null = null,
  ): Observable<AlertViewModel[]> {
    const alertsRequest: AlertsRequest = {
      filters: filters ?? [
        {
          colName: 'StatusId',
          operator: FilterOperator.IN,
          colValue: [AlertStatusType.Open, AlertStatusType.Accepted, AlertStatusType.Resolving],
        },
      ],
      searchValue: '',
      sortColumnName: 'Severity',
      sortOrder: 'desc',
      pageSize: 1000,
      cursor: '',
      includeAlertRemarks: includeAlertRemarks ?? false,
      includeAlertStatusMappings: false,
      includeLatestAlertRemark: this.viewConfiguration.alert.showColumn_LastComment,
    };

    if (deviceAccessGroupIds) {
      alertsRequest.filters!.push({
        colName: 'deviceAccessGroupId',
        operator: FilterOperator.IN,
        colValue: deviceAccessGroupIds,
      });
    }
    if (areaIds) {
      alertsRequest.filters!.push({
        colName: 'areaId',
        operator: FilterOperator.IN,
        colValue: areaIds,
      });
    }

    return combineLatest([
      this.userPreferenceDataService.userPreference$,
      this.alertManagementService.getAlertsRecursive(alertsRequest),
    ]).pipe(
      map((result) =>
        result[1].map((alert) =>
          this.alertMapperService.toAlertViewModel(
            alert,
            result[0].unitSystem,
            removeValuePrefixes,
          ),
        ),
      ),
    );
  }

  getAlertsForChart(
    deviceAccessGroupIds: string[],
    selectedDateRange: Date[],
  ): Observable<AlertChartModel[]> {
    const alertRequest: AlertsRequest = {
      filters: [
        {
          colName: 'deviceAccessGroupId',
          operator: FilterOperator.IN,
          colValue: deviceAccessGroupIds,
        },
        {
          colName: 'StatusId',
          operator: FilterOperator.IN,
          colValue: CoreConstants.CHART_ALERT_STATUSES,
        },
      ],
      searchValue: '',
      sortColumnName: 'deviceTimestamp',
      sortOrder: 'desc',
      pageSize: 1000,
      includeAlertRemarks: true,
      includeAlertStatusMappings: false,
      includeLatestAlertRemark: false,
    };

    if (selectedDateRange.length === 2) {
      alertRequest.filters?.push(
        {
          colName: 'deviceTimestamp',
          operator: FilterOperator.GREATER_EQUAL,
          colValue: selectedDateRange[0].getTime(),
        },
        {
          colName: 'deviceTimestamp',
          operator: FilterOperator.LESS_EQUAL,
          colValue: selectedDateRange[1].getTime(),
        },
      );
    }

    return this.alertManagementService
      .getAlertsRecursive(alertRequest)
      .pipe(
        map((result) =>
          result.map((alert) => this.alertMapperService.toAlertChartModel(alert)).flat(),
        ),
      );
  }

  getAlertRemarks(alertId: string): Observable<AlertRemarkViewModel[]> {
    return combineLatest([
      this.userPreferenceDataService.userPreference$,
      this.alertManagementService.getAlertRemarks(alertId),
    ]).pipe(
      map((result) =>
        result[1].map((a) =>
          this.alertMapperService.toAlertRemarkViewModel(a, result[0].unitSystem),
        ),
      ),
    );
  }

  updateAlertStatus(
    alert: AlertViewModel,
    req: UpdateAlertStatusRequest,
  ): Observable<AlertViewModel> {
    return combineLatest([
      this.userPreferenceDataService.userPreference$,
      this.alertManagementService.updateAlertStatus(alert.id, alert.facilityId!, req),
    ]).pipe(
      map((result) => this.alertMapperService.toAlertViewModel(result[1], result[0].unitSystem)),
    );
  }

  calculateAlertsCount(): Observable<[number, number]> {
    return this.alertManagementService.getAlertStatistics().pipe(
      map((result) => {
        const unResolvedAlertsCount =
          result.openAlerts! + result.acceptedAlerts! + result.resolvingAlerts!;
        const resolvedAlertsCount =
          result.resolvedAlerts! + result.deactivatedAlerts! + result.manuallyClosedAlerts!;
        return [unResolvedAlertsCount, resolvedAlertsCount];
      }),
    );
  }

  shouldRefreshRemarks(alert: AlertViewModel): boolean {
    const alertRemarks = alert.alertRemarks ?? [];
    const showLastComment = this.viewConfiguration.alert.showColumn_LastComment;
    return (
      (showLastComment && alertRemarks.length <= 1) ||
      (!showLastComment && alertRemarks.length === 0)
    );
  }
}
